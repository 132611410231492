import { Permissions } from '@sinagro/shared';
import React from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { canAccessManyPermissions } from 'src/utils/permission';

import { CreditPackageListFilters } from './filters';
import { TopToolbar, CreateButton, FilterButton } from './styles';

export const CreditPackageListActions = () => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const { permissions } = usePermissions();
  const canCreateCreditPackage = canAccessManyPermissions(
    [Permissions.CreditPackage_Full, Permissions.CreditPackage_Write],
    permissions || []
  );

  return (
    <TopToolbar>
      {!isMobile && (
        <FilterButton filters={CreditPackageListFilters()} disableSaveQuery />
      )}
      {canCreateCreditPackage && (
        <CreateButton
          label={t('resources.credit-document-package.list.create')}
        />
      )}
    </TopToolbar>
  );
};
