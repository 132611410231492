import { IRelatedSalesTeam } from '@sinagro/shared';

export type ChoicesProps = {
  id: string;
  name: string;
};

export const formatChoices = (choices: IRelatedSalesTeam[]) => {
  const formattedChoices: ChoicesProps[] = choices.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  return formattedChoices;
};

export const isAValidFilter = (
  newFilter: string,
  filter: string,
  choices: ChoicesProps[]
) => {
  if (filter.length > 1 && newFilter === '') {
    return false;
  }
  const hasFilterEqualFormattedChoice = choices.filter(
    (item) => item.name === newFilter
  );
  return !hasFilterEqualFormattedChoice.length;
};
