import React from 'react';

import { UserListFilters } from './filters';
import { FilterButton, TopToolbar } from './styles';

export const UserListActions = () => {
  return (
    <TopToolbar>
      <FilterButton filters={UserListFilters()} disableSaveQuery />
    </TopToolbar>
  );
};
