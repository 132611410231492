import { CreditPackageDivisionEnum } from '@sinagro/shared';

export const getDivisionChoices = () => {
  const types = Object.values(CreditPackageDivisionEnum).map((type) => ({
    id: type,
    name: `enums.creditPackageDivisionEnum.${type}`,
  }));

  return types;
};
