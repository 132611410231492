import { Chip } from '@mui/material';
import { styled, css } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Chip)`
  color: ${theme.colors.white} !important;
  padding-inline: 2px;
`;

export const Group = styled('div')<{ isMobile: boolean }>`
  display: flex;

  .MuiChip-root:nth-child(2) {
    margin-left: -6px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      .MuiChip-root {
        border: 1px solid ${theme.colors.white};
      }

      .MuiChip-root:nth-child(1) {
        padding-right: 10px;
      }

      .MuiChip-root:nth-child(2) {
        margin-left: -16px;
      }
    `}
`;
