import { AddCircleOutline } from '@mui/icons-material';
import { Typography, Button, Grid } from '@mui/material';
import { styled, css } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { DateInput as DateInputAdmin } from 'react-admin';
import { background } from 'src/components/CreditDocumentStatusChip/utils';
import CustomSimpleFormIterator from 'src/components/CustomSimpleFormIterator';

export const DocumentsList = styled(Grid)`
  background: ${theme.colors.primaryLight};
  padding: 16px;
  border-radius: 4px;
`;

export const Group = styled('div')<{ isMobile: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      flex-direction: column;
      gap: 0;
    `}
`;

export const SubGroup = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const SelectDocumentsText = styled(Typography)`
  color: ${theme.colors.text};
  font-size: ${theme.fontSize.xxl}px;
  margin-bottom: 2rem;
`;

export const AddDocumentButton = styled('a')({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  gap: '0.5rem',
  marginTop: '1rem',
});

export const AddIcon = styled(AddCircleOutline)({
  color: theme.colors.backoffice.blue,
});

export const AddDocument = styled(Typography)({
  color: theme.colors.backoffice.blue,
  fontSize: theme.fontSize.lg,
});

export const RemoveDocumentButton = styled(Button)({
  color: theme.colors.danger,
  [`:hover`]: {
    background: 'transparent',
  },
});

export const DateInput = styled(DateInputAdmin)`
  border: 0px !important;
`;

export const UploadFileInfo = styled(Typography)<{ isMobile: boolean }>`
  color: ${theme.colors.textSecondary};
  font-size: ${theme.fontSize.sm}px;
  margin-bottom: ${({ isMobile }) => (isMobile ? 2 : 0)}rem;
`;

export const SimpleFormIterator = styled(CustomSimpleFormIterator)<{
  isMobile: boolean;
}>`
  .RaSimpleFormIterator-line {
    width: 100%;
    display: flex;
    background: ${theme.colors.white};
    border-radius: 8px;
    border: 0px;
    margin-bottom: 1rem;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-end;

    ${({ isMobile }) =>
      isMobile &&
      css`
        align-items: flex-start;
      `}
  }

  .RaSimpleFormIterator-action {
    visibility: visible;
  }

  .RaSimpleFormIterator-line:has(.pending) {
    border-left: 7px solid ${background.pending};
  }

  .RaSimpleFormIterator-line:has(.review) {
    border-left: 7px solid ${background.review};
  }

  .RaSimpleFormIterator-line:has(.approved) {
    border-left: 7px solid ${background.approved};
  }

  .RaSimpleFormIterator-line:has(.rejected) {
    border-left: 7px solid ${background.rejected};
  }
`;
