import { Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { IRelatedSalesTeam, IUser } from '@sinagro/shared';
import React, { useState } from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useList,
  useNotify,
  useTranslate,
} from 'react-admin';
import SalesTeamSearchAutocomplete from 'src/components/SalesTeamSearchAutocomplete';
import { ChoicesProps } from 'src/components/SalesTeamSearchAutocomplete/utils';

import { Container, Description, Info, SearchContainer } from './styles';

type CreditAnalystProps = {
  user: IUser;
  updateRelatedSalesTeam: (relatedSalesTeam: IRelatedSalesTeam[]) => void;
};

const CreditAnalyst = ({
  user,
  updateRelatedSalesTeam,
}: CreditAnalystProps) => {
  const notify = useNotify();
  const t = useTranslate();
  const [selectedSalesTeam, setSelectedSalesTeam] =
    useState<ChoicesProps | null>(null);
  const [salesTeamList, setSalesTeamList] = useState(
    user.relatedSalesTeam || []
  );
  const listContext = useList({ data: salesTeamList });

  const addRelatedSalesTeamToCreditAnalyst = () => {
    if (selectedSalesTeam === null) return;
    const salesTeamAlreadyRelated =
      salesTeamList?.filter((item) => item.id === selectedSalesTeam?.id)
        .length > 0;
    if (salesTeamAlreadyRelated) {
      return notify(
        t('resources.user.edit.creditAnalyst.salesTeamAlreadyRelated'),
        { type: 'info' }
      );
    }
    const newSalesTeamList = [...salesTeamList, selectedSalesTeam];
    setSalesTeamList(newSalesTeamList);
    updateRelatedSalesTeam(newSalesTeamList);
  };

  const deleteRelatedSalesTeamToCreditAnalyst = (salesTeamId: string) => {
    const newSalesTeamList = salesTeamList.filter(
      (item) => item.id !== salesTeamId
    );
    setSalesTeamList(newSalesTeamList);
    updateRelatedSalesTeam(newSalesTeamList);
  };

  return (
    <Container>
      <Description>
        {t('resources.user.edit.creditAnalyst.instructions')}
      </Description>
      <Info>{t('resources.user.edit.creditAnalyst.info')}</Info>
      <SearchContainer>
        <SalesTeamSearchAutocomplete
          setSelectedSalesTeam={setSelectedSalesTeam}
          label="resources.user.edit.creditAnalyst.search"
        />
        <Button
          size="small"
          type="button"
          variant="contained"
          style={{
            background: theme.colors.backoffice.blue,
            color: theme.colors.white,
          }}
          onClick={addRelatedSalesTeamToCreditAnalyst}
        >
          {t('resources.user.edit.creditAnalyst.addSalesTeam')}
        </Button>
      </SearchContainer>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={<></>}>
          <TextField source="name" />
          <FunctionField
            source="delete"
            label=""
            render={(record: IRelatedSalesTeam) => (
              <IconButton
                onClick={() => deleteRelatedSalesTeamToCreditAnalyst(record.id)}
              >
                <Delete />
              </IconButton>
            )}
          />
        </Datagrid>
      </ListContextProvider>
    </Container>
  );
};

export default CreditAnalyst;
