import { CreditPackageStatusEnum, Permissions } from '@sinagro/shared';
import React, { useCallback, useState } from 'react';
import {
  SelectInput,
  required,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { canAccessManyPermissions } from 'src/utils/permission';

import { RejectionReasonModal } from '../RejectionReasonModal';
import { choicesStatus } from '../utils';

type SelectStautsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scopedFormData: any;
  getSource: (source: string) => string;
  isDisabled: boolean;
};

export const SelectStatus = ({
  getSource,
  scopedFormData,
  isDisabled,
}: SelectStautsProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { setValue } = useFormContext();
  const [previousStatus, setPreviousStatus] = useState(scopedFormData.status);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { permissions } = usePermissions();

  const canUpdateCreditPackage = canAccessManyPermissions(
    [Permissions.CreditPackage_Full, Permissions.CreditPackageDocument_Approve],
    permissions || []
  );

  const disabled =
    isDisabled ||
    !canUpdateCreditPackage ||
    !scopedFormData.file ||
    !scopedFormData.id;

  const bottom = disabled ? 6 : 0;

  const updateStatus = useCallback(
    async (status: CreditPackageStatusEnum, rejectionReason?: string) => {
      await customHttpClient(
        `${apiUrl}/backoffice/credit-document/${scopedFormData.id}/status`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            status,
            rejectionReason,
          }),
        }
      )
        .then(() => {
          setPreviousStatus(status);
          notify(
            'resources.credit-document-package.edit.status.successNotification',
            { type: 'success' }
          );
          refresh();
        })
        .catch(() => {
          setValue(getSource('status'), previousStatus);
          notify(
            'resources.credit-document-package.edit.status.errorNotification',
            { type: 'error' }
          );
          refresh();
        });
    },
    [getSource, notify, previousStatus, refresh, scopedFormData.id, setValue]
  );

  const handleStatusChange = useCallback(
    async (statusSelected: CreditPackageStatusEnum) => {
      if (statusSelected !== CreditPackageStatusEnum.Rejected)
        return updateStatus(statusSelected);

      setIsModalOpen(true);
      return;
    },
    [updateStatus]
  );

  const handleClose = useCallback(() => {
    setValue(getSource('status'), previousStatus);
    refresh();
    setIsModalOpen(false);
  }, [getSource, previousStatus, refresh, setValue]);

  const handleOnConfirmModal = useCallback(
    async (rejectionReason: string) => {
      await updateStatus(CreditPackageStatusEnum.Rejected, rejectionReason);
      setIsModalOpen(false);
    },
    [updateStatus]
  );

  return (
    <>
      <RejectionReasonModal
        isModalOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleOnConfirmModal}
      />
      <SelectInput
        disabled={disabled}
        source={getSource('status')}
        label="resources.credit-document-package.edit.status.selectLabel"
        choices={choicesStatus()}
        variant={disabled ? 'standard' : 'outlined'}
        onChange={(e) => handleStatusChange(e.target.value)}
        validate={required()}
        style={{
          bottom,
        }}
        fullWidth
      />
    </>
  );
};
