import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { SeeAsClient } from 'src/components/SeeAsClient';

import { Button, Content } from './styles';

const SeeAsClientContent = () => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState(true);
  const label = isOpen
    ? t('resources.seeAs.seeLess')
    : t('resources.seeAs.seeMore');
  const icon = isOpen ? <ArrowDropUp /> : <ArrowDropDown />;

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Content>
      <Button onClick={handleIsOpen} isOpen={isOpen}>
        {label}
        {icon}
      </Button>
      {isOpen && <SeeAsClient />}
    </Content>
  );
};

export default SeeAsClientContent;
