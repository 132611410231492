import React, { useState } from 'react';
import {
  Form,
  PasswordInput,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import AuthLayout from 'src/components/AuthLayout';
import Button from 'src/components/Button';
import customHttpClient from 'src/components/CustomHttpClient';
import { useQuery } from 'src/hooks/useQuery';
import { identifyError } from 'src/utils/error';

import { Container, Content } from './styles';
import { passwordValidations } from './utils';

type PasswordRecoveryFormProps = {
  password: string;
};

const PasswordRecovery: React.FC = () => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const query = useQuery();
  const token = query.get('token');
  const newUser = !!query.get('newUser');
  const type = newUser ? 'newUser' : 'create';

  const samePassword = (input: string) => {
    return input === password
      ? undefined
      : 'passwordRecovery.validations.samePassword';
  };

  const onSubmit = async ({ password }: PasswordRecoveryFormProps) => {
    setLoading(true);
    await customHttpClient(`${apiUrl}/user/password/change/by-token`, {
      method: 'post',
      body: JSON.stringify({
        token,
        newPassword: password,
      }),
    })
      .then(() => {
        notify(t('passwordRecovery.success'), { type: 'success' });
        redirect('/login');
      })
      .catch((error) => {
        notify(identifyError(error), { type: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!token) {
    redirect('/login');
  }

  return (
    <AuthLayout title={t(`passwordRecovery.${type}.title`)}>
      <Container>
        <Content>
          <Form
            onSubmit={(data) => onSubmit(data as PasswordRecoveryFormProps)}
          >
            <PasswordInput
              source="password"
              label={`passwordRecovery.${type}.newPassword`}
              variant="outlined"
              required
              fullWidth
              style={{ marginTop: 0, marginBottom: 16 }}
              validate={passwordValidations}
              helperText="passwordRecovery.helper"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <PasswordInput
              source="confirmPassword"
              label={`passwordRecovery.${type}.confirmNewPassword`}
              variant="outlined"
              required
              fullWidth
              style={{ marginTop: 0, marginBottom: 16 }}
              validate={samePassword}
            />
            <Button
              text={t(`passwordRecovery.${type}.createPassword`)}
              loading={loading}
            />
          </Form>
        </Content>
      </Container>
    </AuthLayout>
  );
};

export default PasswordRecovery;
