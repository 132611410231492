import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';
import { PORTAL_URL } from 'src/components/SeeAsClient/utils';
import Title from 'src/components/Title';
import { useSeeAsContext } from 'src/contexts/seeAs';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import SeeAsClientContent from './seeAsClientContent';
import { Header, IframeContent } from './styles';

export const SeeAs = () => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const { isLoading } = useSeeAsContext();

  return (
    <>
      <Header>
        <Title title={t('resources.seeAs.title')} />
        {isMobile && <SeeAsClientContent />}
      </Header>
      {isLoading !== null && (
        <IframeContent>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <iframe src={PORTAL_URL} />
          )}
        </IframeContent>
      )}
    </>
  );
};
