/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import Logo from 'src/assets/logo.png';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { customTheme } from 'src/utils/theme';

import {
  Container,
  Content,
  ContentSidebar,
  Image,
  Sidebar,
  Text,
  Title,
  TitleSidebar,
} from './styles';

const AuthLayout: React.FC<{ title: string }> = ({ children, title }) => {
  const isMobile = useIsMobile();
  const t = useTranslate();

  return (
    <ThemeProvider theme={customTheme}>
      <Container container>
        {!isMobile && (
          <Sidebar item sm={4} lg={4}>
            <ContentSidebar>
              <TitleSidebar variant="h1">
                {t('common.backoffice.title')}
              </TitleSidebar>
              <Text>{t('common.backoffice.description')}</Text>
            </ContentSidebar>
          </Sidebar>
        )}
        <Content item xs={12} sm={8} lg={8}>
          <Image src={Logo} />
          <Title variant="h1">{title}</Title>
          {children}
        </Content>
      </Container>
    </ThemeProvider>
  );
};

export default AuthLayout;
