import { CreditPackageClient } from '@sinagro/shared';
import React, { useState } from 'react';
import { CreditDocumentPackageContext } from 'src/contexts/creditDocumentPackage';

type CreditDocumentPackageProviderProps = {
  children: React.ReactNode;
};

export const CreditDocumentPackageProvider = ({
  children,
}: CreditDocumentPackageProviderProps): JSX.Element => {
  const [selectedClient, setSelectedClient] =
    useState<CreditPackageClient | null>(null);

  const onChangeClient = (client: CreditPackageClient | null) => {
    setSelectedClient(client);
  };

  return (
    <CreditDocumentPackageContext.Provider
      value={{ selectedClient, onChangeClient }}
    >
      {children}
    </CreditDocumentPackageContext.Provider>
  );
};
