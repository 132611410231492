import React from 'react';
import {
  List,
  TextField,
  EmailField,
  useTranslate,
  EditButton,
} from 'react-admin';
import ResourceContainer from 'src/components/ResourceContainer';

import { UserListFilters } from './filters';
import { UserListActions } from './listActions';
import { Datagrid } from './styles';

export const UserList = () => {
  const t = useTranslate();

  return (
    <ResourceContainer title={t('resources.user.title')}>
      <List
        actions={<UserListActions />}
        filters={UserListFilters()}
        disableSyncWithLocation={true}
      >
        <Datagrid bulkActionButtons={<></>}>
          <TextField source="name" />
          <EmailField source="email" />
          <TextField source="rbacRole.name" />
          <EditButton />
        </Datagrid>
      </List>
    </ResourceContainer>
  );
};
