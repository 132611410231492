import { Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { IDigitalConsultantRelatedClient, IUser } from '@sinagro/shared';
import React, { useState } from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useList,
  useNotify,
  useTranslate,
} from 'react-admin';
import ClientSearchAutocomplete from 'src/components/ClientSearchAutocomplete';
import { ChoicesProps } from 'src/components/ClientSearchAutocomplete/utils';

import { Container, Label, SearchContainer } from './styles';

type DigitalConsultantProps = {
  user: IUser;
  updateRelatedClients: (
    relatedClients: IDigitalConsultantRelatedClient[]
  ) => void;
};

const DigitalConsultant = ({
  user,
  updateRelatedClients,
}: DigitalConsultantProps) => {
  const notify = useNotify();
  const t = useTranslate();
  const [selectedClient, setSelectedClient] = useState<ChoicesProps | null>(
    null
  );
  const [clientsList, setClientsList] = useState(
    user.digitalConsultantRelatedClients || []
  );
  const listContext = useList({ data: clientsList });

  const addRelatedClientsToConsultant = () => {
    if (selectedClient === null) return;
    const clientAlreadyRelated =
      clientsList?.filter((item) => item.id === selectedClient?.id).length > 0;
    if (clientAlreadyRelated) {
      return notify(
        t('resources.user.edit.digitalConsultant.clientAlreadyRelated'),
        { type: 'info' }
      );
    }
    const newClientList = [...clientsList, selectedClient];
    setClientsList(newClientList);
    updateRelatedClients(newClientList);
  };

  const deleteRelatedClientsToConsultant = (clientId: string) => {
    const newClientList = clientsList.filter((item) => item.id !== clientId);
    setClientsList(newClientList);
    updateRelatedClients(newClientList);
  };

  return (
    <Container>
      <Label>{t('resources.user.edit.digitalConsultant.instructions')}</Label>
      <SearchContainer>
        <ClientSearchAutocomplete
          setSelectedClient={setSelectedClient}
          label="resources.seeAs.search"
        />
        <Button
          size="small"
          type="button"
          variant="contained"
          style={{
            background: theme.colors.backoffice.blue,
            color: theme.colors.white,
          }}
          onClick={addRelatedClientsToConsultant}
        >
          {t('resources.user.edit.digitalConsultant.addClient')}
        </Button>
      </SearchContainer>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={<></>}>
          <TextField source="name" />
          <TextField
            source="document"
            label={t('resources.user.edit.digitalConsultant.document')}
          />
          <TextField
            source="crmId"
            label={t('resources.user.edit.digitalConsultant.crmId')}
          />
          <FunctionField
            source="delete"
            label=""
            render={(record: IDigitalConsultantRelatedClient) => (
              <IconButton
                onClick={() => deleteRelatedClientsToConsultant(record.id)}
              >
                <Delete />
              </IconButton>
            )}
          />
        </Datagrid>
      </ListContextProvider>
    </Container>
  );
};

export default DigitalConsultant;
