import React, { useEffect, useState } from 'react';
import { SelectInput, TextInput } from 'react-admin';

import { getRolesChoices } from './utils';

interface RoleChoice {
  id: string;
  name: string;
}

export const UserListFilters = () => {
  const [choices, setChoices] = useState<RoleChoice[]>([]);

  useEffect(() => {
    const getChoices = async () => {
      const rolesChoices = await getRolesChoices();
      setChoices(rolesChoices);
    };
    getChoices();
  }, []);
  return [
    <TextInput
      key="name"
      source="name"
      label="resources.user.list.filters.name"
      variant="outlined"
    />,
    <TextInput
      key="email"
      source="email"
      label="resources.user.list.filters.email"
      variant="outlined"
    />,
    <SelectInput
      key="rbacRoleId"
      source="rbacRoleId"
      variant="outlined"
      label="resources.user.list.filters.rbacRoleId"
      choices={choices}
    />,
  ];
};
