import { CreditPackageClient } from '@sinagro/shared';
import React from 'react';
import { SelectInput, useRecordContext } from 'react-admin';

type SelectClientToAttachToDocumentProps = {
  getSource: (source: string) => string;
  selectedClient: CreditPackageClient | null;
};

export const SelectClientToAttachToDocument = ({
  getSource,
  selectedClient,
}: SelectClientToAttachToDocumentProps) => {
  const record = useRecordContext();
  const currentClient = record ? record.client : selectedClient;
  const relatedClients = currentClient
    ? [
        {
          name: `${currentClient.name} - ${currentClient.id}`,
          id: currentClient.id,
        },
        ...currentClient.children.map(
          (client: { name: string; id: string }) => ({
            name: `${client.name} - ${client.id}`,
            id: client.id,
          })
        ),
      ]
    : [{ id: '', name: '' }];
  return (
    <SelectInput
      source={getSource('relatedClient')}
      label="resources.credit-document-package.create.addClientToDocument"
      disabled={!relatedClients.length}
      choices={relatedClients}
      variant={'outlined'}
      fullWidth
    />
  );
};
