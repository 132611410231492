import React from 'react';
import { SelectInput, TextInput, DateInput } from 'react-admin';

import { getUserTypeChoices, hasTorqFarmRelatedChoices } from './utils';

export const filters = [
  <SelectInput
    key="clientType"
    variant="outlined"
    source="clientType"
    choices={getUserTypeChoices()}
  />,
  <DateInput key="createdAt(min)" variant="outlined" source="createdAt(min)" />,
  <DateInput key="createdAt(max)" variant="outlined" source="createdAt(max)" />,
  <TextInput key="document" variant="outlined" source="document" />,
  <TextInput key="sapId" variant="outlined" source="sapId" />,
  <TextInput key="crmId" variant="outlined" source="crmId" />,
  <SelectInput
    key="hasTorqFarmRelated"
    variant="outlined"
    source="hasTorqFarmRelated"
    choices={hasTorqFarmRelatedChoices}
  />,
];
