import { Container as ContainerMUI, Grid } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Grid)({
  maxWidth: 550,
  display: 'flex',
  width: '100%',
});

export const Content = styled(ContainerMUI)({});

export const Footer = styled(Grid)({
  marginTop: 24,
  textAlign: 'center',
  [`& a`]: {
    textDecoration: 'underline !important',
  },
});
