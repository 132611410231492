import React from 'react';
import { Edit, useNotify, useRedirect, useTranslate } from 'react-admin';
import ResourceContainer from 'src/components/ResourceContainer';

import { CreditForm } from '../Form/creditForm';

export const CreditPackageEdit = () => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <ResourceContainer
      title={t('resources.credit-document-package.edit.title')}
      noStyling
    >
      <Edit
        mutationMode="pessimistic"
        mutationOptions={{
          onSuccess: () => {
            notify('resources.credit-document-package.edit.mutationSuccess', {
              type: 'success',
            });
            redirect('list', 'credit-document-package');
          },
        }}
      >
        <CreditForm isEdit={true} />
      </Edit>
    </ResourceContainer>
  );
};
