import { Container as ContainerMUI, Grid } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Grid)({
  maxWidth: 550,
  display: 'flex',
  width: '100%',
});

export const Content = styled(ContainerMUI)({});
