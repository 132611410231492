/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { LayoutComponent } from 'react-admin';
import { usePageTracking } from 'src/hooks/analytics';

import CustomAppBar from './CustomAppBar';
import CustomSidebar from './CustomSidebar';
import { Layout } from './styles';

const CustomLayout: LayoutComponent = (props) => {
  usePageTracking();

  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      sidebar={(props: any) => <CustomSidebar {...props} />}
    />
  );
};

export default CustomLayout;
