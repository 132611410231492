import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)({
  marginBottom: '2rem',
});

export const Description = styled(Typography)({
  color: theme.colors.text,
  fontSize: theme.fontSize.md,
});

export const Info = styled(Typography)({
  color: theme.colors.textSecondary,
  fontSize: theme.fontSize.md,
});

export const SearchContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2rem',
});
