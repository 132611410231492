import { ChevronLeft } from '@mui/icons-material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import React from 'react';
import { useRedirect } from 'react-admin';

type BackButtonProps = {
  redirectTo: string;
  resource: string;
  style?: React.CSSProperties;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const BackButton = ({ redirectTo, resource, style }: BackButtonProps) => {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect(redirectTo, resource);
  };
  return (
    <a
      style={{
        color: theme.colors.secondary,
        cursor: 'pointer',
        ...style,
      }}
      onClick={handleClick}
    >
      <ChevronLeft />
    </a>
  );
};

export default BackButton;
