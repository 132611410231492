import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import {
  SimpleList,
  TopToolbar as raTopToolbar,
  CreateButton as CreateButtonRA,
  FilterButton as FilterButtonRA,
} from 'react-admin';

export const Container = styled(Grid)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? 0 : 16)}px;
`;

export const Header = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-end',
});

export const TopToolbar = styled(raTopToolbar)({
  marginBottom: 10,
  gap: 16,
});

export const FilterButton = styled(FilterButtonRA)`
  display: flex;
  font-weight: bold;
  border-radius: 4px;
  min-height: 33.5px;
  align-items: center;

  .MuiButton-text {
    color: ${theme.colors.text};
  }
`;

export const CreateButton = styled(CreateButtonRA)`
  border: 1px solid #1976d2;
  color: #1976d2;
  font-weight: bold;
  min-height: 33.5px;
`;

export const List = styled(SimpleList)({
  [`.MuiListItemButton-root`]: {
    padding: '0px !important',
  },
});

export const Card = styled(Grid)({
  background: theme.colors.white,
  padding: 8,
  borderRadius: 8,
  margin: '4px 0px',
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px -2px rgba(0, 0, 0, 0.20)',
});

export const Row = styled(Grid)({
  marginTop: 8,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const Column = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});
