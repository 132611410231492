import { errorList, unknownError } from '@sinagro/shared/errors';

type Error = {
  message: string;
};

export const identifyError = (errorMessage: Error) => {
  const knownError = errorList.find(
    (errClass) => errorMessage.message === errClass.message
  );

  return knownError?.message || unknownError.message;
};
