import { CreditPackageCreditRequest } from '@sinagro/shared';
import { Translate } from 'react-admin';
import { formatDatePTBR, formatValueToCurrency } from 'src/utils/format';

export const formatCreditRequests = (
  creditRequest: CreditPackageCreditRequest,
  t: Translate
) => {
  const formatted = `${formatValueToCurrency(
    creditRequest.valueInCents.toString()
  )} ${t(
    'resources.credit-document-package.creditRequest.history.requested'
  )} ${formatDatePTBR(creditRequest.requestedAt)}. ${t(
    'resources.credit-document-package.creditRequest.history.by'
  )} ${creditRequest.requestedBy?.userEmail}. ${t(
    'resources.credit-document-package.creditRequest.history.requestedDocs'
  )}`;

  return formatted;
};
