/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CreditPackageClient,
  CreditPackageStatusEnum,
  Permissions,
  creditDocumentTemplatesDB,
} from '@sinagro/shared';
import React, { useState } from 'react';
import {
  useTranslate,
  TextInput,
  ArrayInput,
  FormDataConsumer,
  Confirm,
  usePermissions,
  SelectInput,
} from 'react-admin';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { formatDate, formatDatePTBR } from 'src/utils/format';
import { canAccessManyPermissions } from 'src/utils/permission';

import { DocumentFilesInput } from '../DocumentFilesInput';
import { SelectClientToAttachToDocument } from '../SelectClientToAttachToDocument';
import { SelectStatus } from '../SelectStatus';
import {
  DocumentsList,
  AddDocument,
  AddDocumentButton,
  AddIcon,
  RemoveDocumentButton,
  SelectDocumentsText,
  DateInput,
  SimpleFormIterator,
  Group,
  UploadFileInfo,
  SubGroup,
} from './styles';

type DocumentsInputProps = {
  isEdit: boolean;
  client: CreditPackageClient | null;
};

export const DocumentsInput = ({ isEdit, client }: DocumentsInputProps) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const { permissions } = usePermissions();
  const [fileLoadingId, setFileLoadingId] = useState<string | null>(null);
  const [removeFileConfirm, setRemoveFileConfirm] = useState<any>(null);
  const [isRemoveFileModalOpen, setIsRemoveFileModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState('');

  const canAccessDocumentDeleteOption = canAccessManyPermissions(
    [Permissions.CreditPackageDocument_Delete, Permissions.CreditPackage_Full],
    permissions || []
  );

  return (
    <DocumentsList>
      <SelectDocumentsText>
        {t('resources.credit-document-package.create.selectDocuments')}
      </SelectDocumentsText>

      <ArrayInput source="documents" label="">
        <SimpleFormIterator
          isMobile={isMobile}
          disableClear
          disableReordering
          inline
          fullWidth
          addButton={
            <AddDocumentButton>
              <AddIcon />
              <AddDocument>
                {t('resources.credit-document-package.create.addDocument')}
              </AddDocument>
            </AddDocumentButton>
          }
          removeButton={
            <RemoveDocumentButton size="small" type="button" variant="text">
              {t('resources.credit-document-package.file.removeItem')}
            </RemoveDocumentButton>
          }
          itemRemovalConfirmationTitle={t(
            'resources.credit-document-package.file.confirmRemovalItem.title'
          )}
          itemRemovalConfirmationContent={t(
            'resources.credit-document-package.file.confirmRemovalItem.content'
          )}
          disableRemove={!canAccessDocumentDeleteOption}
        >
          <FormDataConsumer>
            {({ scopedFormData, getSource }) =>
              getSource && (
                <>
                  <div className={scopedFormData.status} />
                  <Group isMobile={isMobile}>
                    <SelectInput
                      type="text"
                      source={getSource('description')}
                      label="resources.credit-document-package.create.description"
                      variant="outlined"
                      required
                      fullWidth
                      choices={creditDocumentTemplatesDB.map((doc) => ({
                        id: doc.documentData.description,
                        name: doc.documentData.description,
                      }))}
                    />
                    <SelectClientToAttachToDocument
                      selectedClient={client}
                      getSource={getSource}
                    />
                    <TextInput
                      type="text"
                      source={getSource('additionalText')}
                      label="resources.credit-document-package.create.additionalText"
                      variant="outlined"
                      fullWidth
                      defaultValue={''}
                    />
                  </Group>
                  <Group isMobile={isMobile}>
                    <DocumentFilesInput
                      documentWithFileLoadingId={fileLoadingId}
                      setIsLoading={setFileLoadingId}
                      isMobile={isMobile}
                      scopedFormData={scopedFormData}
                      setDocumentName={setDocumentName}
                      setIsRemoveFileModalOpen={setIsRemoveFileModalOpen}
                      setRemoveFileConfirm={setRemoveFileConfirm}
                      getSource={getSource}
                    />
                    {isEdit &&
                      scopedFormData &&
                      scopedFormData?.uploadedBy &&
                      scopedFormData?.uploadedAt && (
                        <UploadFileInfo isMobile={isMobile}>
                          {`${t(
                            'resources.credit-document-package.create.uploadedBy'
                          )}: ${scopedFormData.uploadedBy.userName}`}
                          <br />
                          {`${t(
                            'resources.credit-document-package.create.uploadedAt'
                          )}: ${formatDatePTBR(scopedFormData.uploadedAt)}`}
                        </UploadFileInfo>
                      )}
                  </Group>
                  {isEdit && (
                    <Group isMobile={isMobile}>
                      <SubGroup>
                        {getSource && (
                          <DateInput
                            source={getSource('validUntil')}
                            label={t(
                              'resources.credit-document-package.create.validUntil'
                            )}
                            variant="outlined"
                            format={formatDate}
                            placeholder="dd/mm/aaaa"
                            fullWidth
                          />
                        )}
                        {scopedFormData && scopedFormData.id && getSource && (
                          <SelectStatus
                            isDisabled={
                              !!(
                                fileLoadingId &&
                                fileLoadingId === scopedFormData.id
                              )
                            }
                            getSource={getSource}
                            scopedFormData={scopedFormData}
                          />
                        )}
                      </SubGroup>
                      {scopedFormData &&
                        scopedFormData.id &&
                        scopedFormData.status ===
                          CreditPackageStatusEnum.Rejected &&
                        scopedFormData.rejections?.length > 0 &&
                        getSource && (
                          <TextInput
                            type="text"
                            source={getSource('rejectionReason')}
                            label="resources.credit-document-package.create.rejectionReason"
                            variant="standard"
                            fullWidth
                            defaultValue={
                              scopedFormData.rejections[0].description
                            }
                            disabled
                            style={{ bottom: isMobile ? 0 : 6 }}
                          />
                        )}
                    </Group>
                  )}
                </>
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Confirm
        isOpen={isRemoveFileModalOpen}
        title={`${t(
          'resources.credit-document-package.file.confirmRemoval.title'
        )} ${documentName}?`}
        content={t(
          'resources.credit-document-package.file.confirmRemoval.message'
        )}
        onConfirm={() => {
          setIsRemoveFileModalOpen(false);
          removeFileConfirm && removeFileConfirm.delete();
        }}
        onClose={() => {
          setIsRemoveFileModalOpen(false);
          removeFileConfirm && removeFileConfirm.cancel();
        }}
      />
    </DocumentsList>
  );
};
