/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRole } from '@sinagro/shared';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';

export const getRolesChoices = async (): Promise<[]> => {
  const result = await customHttpClient(`${apiUrl}/backoffice/role`, {
    method: 'get',
  });

  return result.json.map((role: IRole) => ({ id: role.id, name: role.name }));
};
