import { CreditPackageClient } from '@sinagro/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { AutocompleteInput, useNotify } from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { useCreditDocumentPackageContext } from 'src/contexts/creditDocumentPackage';
import { identifyError } from 'src/utils/error';

import { formatChoices, isAValidFilter } from './utils';

type ClientSearchAutocompleteProps = {
  setSelectedClient: (client: CreditPackageClient | null) => void;
  label: string;
  disabled: boolean;
};

const ClientDocumentPackageSearchAutocomplete = ({
  setSelectedClient,
  label,
  disabled,
}: ClientSearchAutocompleteProps) => {
  const notify = useNotify();
  const [filter, setFilter] = useState('');
  const [choices, setChoices] = useState<CreditPackageClient[]>([]);
  const { selectedClient } = useCreditDocumentPackageContext();
  const autocompleteChoices = (selectedClient && [selectedClient]) || choices;

  const onChangeClient = (newClient: string) => {
    if (newClient === '') {
      setFilter('');
    }
    const client = choices.filter((item) => item.id === newClient)[0];
    setSelectedClient(client);
  };

  const onChangeFilter = (newFilter: string) => {
    if (isAValidFilter(newFilter, filter, choices)) {
      setFilter(newFilter);
    }
  };

  const fetchClients = useCallback(async () => {
    const queryParams = filter ? `?query=${filter}` : '';
    return customHttpClient(
      `${apiUrl}/backoffice/credit-document-package/client${queryParams}`,
      { method: 'get' }
    )
      .then((res) => {
        const data = res.json.results;
        const formattedChoices = formatChoices(data);
        setChoices(formattedChoices);
      })
      .catch((error) => {
        notify(identifyError(error), { type: 'error' });
      });
  }, [notify, filter]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <div style={{ maxWidth: '400px', width: '100%' }}>
      <AutocompleteInput
        disabled={disabled}
        source="client.id"
        label={label}
        variant="outlined"
        helperText=""
        choices={autocompleteChoices}
        onChange={onChangeClient}
        setFilter={onChangeFilter}
        defaultValue={''}
        fullWidth
      />
    </div>
  );
};

export default ClientDocumentPackageSearchAutocomplete;
