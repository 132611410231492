import { OpenInNew } from '@mui/icons-material';
import { InfoOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { Form, Link, useTranslate } from 'react-admin';
import ClientSearchAutocomplete from 'src/components/ClientSearchAutocomplete';
import { useSeeAsContext } from 'src/contexts/seeAs';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { ChoicesProps } from '../ClientSearchAutocomplete/utils';
import { Modal } from '../Modal';
import {
  Button,
  Container,
  Info,
  InfoButton,
  InfoOkButton,
  NoRegisteredTag,
  RegisteredTag,
  SupportContent,
  Text,
  Wrapper,
} from './styles';
import { openInNewTab, values } from './utils';

export const FORMS_URL = process.env.REACT_APP_FORMS_URL;

export const SeeAsClient = () => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const { onChangeClient, selectedClient, registeredClient } =
    useSeeAsContext();

  const type = isMobile ? 'mobile' : 'web';
  const { clientSearchAutocompleteLabel, supportFormLabel, width } =
    values[type];

  const onChangeSelectedClient = (client: ChoicesProps | null) => {
    onChangeClient && onChangeClient(client);
  };

  const tag = registeredClient ? (
    <RegisteredTag size="small" label={t('resources.seeAs.registered')} />
  ) : (
    <NoRegisteredTag size="small" label={t('resources.seeAs.noRegistered')} />
  );

  return (
    <Container isMobile={isMobile}>
      {!isMobile && <Text>{t('resources.seeAs.instruction')}</Text>}
      <Form>
        <ClientSearchAutocomplete
          setSelectedClient={onChangeSelectedClient}
          label={clientSearchAutocompleteLabel}
          groupBy="document"
        />
        {isMobile && selectedClient && tag}
      </Form>
      {selectedClient && (
        <>
          {!isMobile && tag}
          <Wrapper>
            <Button
              size="small"
              variant="contained"
              onClick={openInNewTab}
              endIcon={<OpenInNew />}
              style={{ width }}
            >
              {t('resources.seeAs.openPortal')}
            </Button>
            <InfoButton onClick={() => setIsOpen(true)}>
              <InfoOutlined />
            </InfoButton>
          </Wrapper>
        </>
      )}

      <SupportContent>
        <Link
          href={FORMS_URL}
          to={FORMS_URL || ''}
          target="_blank"
          style={{
            textDecoration: 'underline',
          }}
        >
          {t(supportFormLabel)}
        </Link>
      </SupportContent>

      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={t('resources.seeAs.info.title')}
        content={
          <>
            <Info>{t('resources.seeAs.info.message')}</Info>
            <InfoOkButton onClick={() => setIsOpen(false)}>
              {t('resources.seeAs.info.ok')}
            </InfoOkButton>
          </>
        }
      />
    </Container>
  );
};
