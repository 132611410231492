import * as Sentry from '@sentry/react';
import { createBrowserHistory as createHistory } from 'history';
import * as React from 'react';
import { Admin, AdminRouter, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { version } from '../package.json';
import AppDataProvider from './components/AppDataProvider';
import authProvider from './components/AuthProvider';
import CustomLayout from './components/CustomLayout';
import ErrorFallback from './components/ErrorFallback';
import { i18nProvider } from './components/i18nProvider';
import { items } from './components/MenuItem/items';
import ForgotPassword from './pages/ForgotPassword';
import LoginPage from './pages/LoginPage';
import PasswordRecovery from './pages/PasswordRecovery';
import { CreditDocumentPackageProvider } from './providers/creditDocumentPackage';
import { SeeAsProvider } from './providers/seeAs';
import { UserAuthProvider } from './providers/userAuth';
import { appTheme } from './utils/theme';
import './utils/sso';

if (process.env.REACT_APP_ENV !== 'development')
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    release: version,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });

const history = createHistory();

const dataProvider = AppDataProvider;

const App = () => (
  <Sentry.ErrorBoundary
    fallback={
      <AdminRouter>
        <ErrorFallback />
      </AdminRouter>
    }
  >
    <UserAuthProvider>
      <SeeAsProvider>
        <CreditDocumentPackageProvider>
          <Admin
            theme={appTheme}
            loginPage={LoginPage}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            layout={CustomLayout}
            history={history}
          >
            {(permissions) => {
              const menuItems = items(permissions).map((item) => {
                if (item.hasPermission) {
                  return (
                    <Resource
                      key={item.name}
                      name={item.name}
                      icon={item.menuIcon}
                      list={item.list}
                      create={item.create}
                      edit={item.edit}
                    />
                  );
                }
              });

              return menuItems;
            }}
            <CustomRoutes noLayout>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/password-recovery" element={<PasswordRecovery />} />
            </CustomRoutes>
          </Admin>
        </CreditDocumentPackageProvider>
      </SeeAsProvider>
    </UserAuthProvider>
  </Sentry.ErrorBoundary>
);

export default App;
