import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { DeleteButton as DeleteButtonAdmin } from 'react-admin';

export const Container = styled(Grid)<{ setMinHeight: boolean }>((props) => ({
  padding: 16,
  display: 'flex',
  minHeight: props.setMinHeight ? '70vh' : 'auto',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const Options = styled(Grid)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

export const DeleteButton = styled(DeleteButtonAdmin)({
  background: theme.colors.danger,
  color: theme.colors.white,
  lineHeight: '1.75 !important',
  [':hover']: {
    background: theme.colors.danger,
  },
  marginTop: '2rem',
});

export const Header = styled(Grid)({
  display: 'flex',
  paddingTop: '10px',
  gap: '1rem',
});
