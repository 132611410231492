import React from 'react';
import { useTranslate } from 'react-admin';

import { Text } from './styles';

export const DeleteDialogue = () => {
  const t = useTranslate();
  return (
    <>
      <Text>
        {t('resources.credit-document-package.delete.modal.contentSubtitle')}
      </Text>
      <Text>
        {t('resources.credit-document-package.delete.modal.contentText')}
      </Text>
    </>
  );
};
