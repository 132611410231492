import React from 'react';
import { useTranslate } from 'react-admin';
import { Modal } from 'src/components/Modal';

import {
  AlertContainer,
  AlertIcon,
  AlertText,
  CancelButton,
  ModalContent,
  ModalFooter,
  ModalText,
  RequestButton,
} from './styles';

type ConfirmationModal = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({ open, onClose, onConfirm }: ConfirmationModal) => {
  const t = useTranslate();

  return (
    <Modal
      open={open}
      title={t('resources.credit-document-package.creditRequest.modal.title')}
      content={
        <ModalContent>
          <AlertContainer>
            <AlertIcon />
            <AlertText>
              {t(
                'resources.credit-document-package.creditRequest.modal.warning'
              )}
            </AlertText>
          </AlertContainer>
          <ModalText>
            {t(
              'resources.credit-document-package.creditRequest.modal.descPart1'
            )}
          </ModalText>
          <ModalText>
            {t(
              'resources.credit-document-package.creditRequest.modal.descPart2'
            )}
          </ModalText>
          <ModalFooter>
            <CancelButton
              size="small"
              type="button"
              variant="outlined"
              onClick={onClose}
            >
              {t('resources.credit-document-package.creditRequest.cancel')}
            </CancelButton>
            <RequestButton
              size="small"
              type="button"
              variant="contained"
              onClick={onConfirm}
            >
              {t('resources.credit-document-package.creditRequest.request')}
            </RequestButton>
          </ModalFooter>
        </ModalContent>
      }
      onClose={onClose}
    />
  );
};

export default ConfirmationModal;
