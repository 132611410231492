/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { UserMenuContext, UserMenuContextValue } from 'react-admin';

type Props = {
  children: any;
  value: any;
};

export const UserMenuContextProvider = ({ children, value }: Props) => (
  <UserMenuContext.Provider value={value}>{children}</UserMenuContext.Provider>
);

export type UserMenuContextProviderProps = {
  children: ReactNode;
  value: UserMenuContextValue;
};
