const publicRoutes = ['/login', '/forgot-password', '/password-recovery'];

export const isPublicRoute = () => {
  const currentRoute = window.location.pathname;
  const verifyIfIsPublicRoute = publicRoutes.filter(
    (item) => item === currentRoute
  );
  return !!verifyIfIsPublicRoute.length;
};

export const isSeeAsRoute = () => {
  const seeAsRoute = '/seeAs';
  const currentRoute = window.location.pathname;
  return seeAsRoute === currentRoute;
};
