import { Grid } from '@mui/material';
import { CreditPackageDivisionEnum, WinningQuote } from '@sinagro/shared';
import _ from 'lodash';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import CreditDocumentGroupedDivisionChips from 'src/components/CreditDocumentDivisionChips';
import CreditDocumentStatusChip from 'src/components/CreditDocumentStatusChip';
import Subtitle from 'src/components/Subtitle';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Header } from './styles';

export const EditCreditPackageHeader = () => {
  const t = useTranslate();
  const record = useRecordContext();
  const isMobile = useIsMobile();
  const divisions: CreditPackageDivisionEnum[] =
    _.uniq<CreditPackageDivisionEnum>(
      record.relatedQuotes
        .map((quote: WinningQuote) => quote.division)
        .filter((division: CreditPackageDivisionEnum) =>
          Object.values(CreditPackageDivisionEnum).includes(division)
        )
    );

  return (
    <Header isMobile={isMobile}>
      <Subtitle
        text={t('resources.credit-document-package.edit.subtitle')}
        isMobile={isMobile}
        backButton={{
          redirectTo: 'list',
          resource: 'credit-document-package',
        }}
      />
      <Grid display="flex" gap={1} direction="row">
        <CreditDocumentGroupedDivisionChips divisions={divisions || []} />
        <CreditDocumentStatusChip type={record.internalStatus} />
      </Grid>
    </Header>
  );
};
