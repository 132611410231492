import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { Link, useTranslate } from 'react-admin';

export type FaqProps = {
  open: boolean;
};

export const Faq = ({ open }: FaqProps) => {
  const t = useTranslate();

  return (
    <>
      {open && (
        <Link
          href="https://sinagro.sharepoint.com/sites/SINforme#/FAQ"
          to="https://sinagro.sharepoint.com/sites/SINforme#/FAQ"
          target="_blank"
          style={{
            marginBottom: '2rem',
            textDecoration: 'underline',
            alignSelf: 'center',
          }}
        >
          {t('resources.sidebar.support')}
        </Link>
      )}
      {!open && (
        <Link
          href="https://sinagro.sharepoint.com/sites/SINforme#/FAQ"
          to="https://sinagro.sharepoint.com/sites/SINforme#/FAQ"
          target="_blank"
          style={{
            marginBottom: '2rem',
            alignSelf: 'center',
          }}
        >
          <HelpIcon />
        </Link>
      )}
    </>
  );
};
