import { analytics } from '@sinagro/frontend-shared/firebase';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    analytics().logEvent('page_view', {
      page_path: location.pathname,
    });
  }, [location]);
};
