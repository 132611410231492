import { Permissions } from '@sinagro/shared';

export const canAccess = (
  requiredPermission: Permissions,
  userPermissions: Permissions[]
) => {
  const hasPermission = userPermissions.filter(
    (permission) => permission === requiredPermission
  );
  return !!hasPermission.length;
};

export const canAccessManyPermissions = (
  requiredPermissions: Permissions[],
  userPermissions: Permissions[]
) => {
  const hasPermission = userPermissions.filter((up) =>
    requiredPermissions.find((rp) => up === rp)
  );
  return !!hasPermission.length;
};
