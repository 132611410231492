/* eslint-disable @typescript-eslint/no-explicit-any */
import { SSOEventTypeEnum } from '@sinagro/frontend-shared/src/types';

export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

export const sendCheckAuthMessage = (event: any, eventOrigin: string) => {
  event.source.postMessage(
    {
      type: SSOEventTypeEnum.CheckAuthStart,
    },
    eventOrigin
  );
};

export const sendAuthenticatedMessage = (
  event: any,
  eventOrigin: string,
  token: string
) => {
  event.source.postMessage(
    {
      type: SSOEventTypeEnum.CheckAuthFinished,
      token,
      authenticated: true,
    },
    eventOrigin
  );
};

export const sendNotAuthenticatedMessage = (
  event: any,
  eventOrigin: string
) => {
  event.source.postMessage(
    {
      type: SSOEventTypeEnum.CheckAuthFinished,
      authenticated: false,
    },
    eventOrigin
  );
};
