import { UserTypeEnum } from '@sinagro/shared';
import React from 'react';
import { useTranslate } from 'react-admin';

import { Container } from './styles';
import { background } from './utils';

type UserTypeChipProps = {
  type: UserTypeEnum;
};

const UserTypeChip = ({ type }: UserTypeChipProps) => {
  const t = useTranslate();
  const label = t(`enums.userTypeEnum.${type}`);
  return (
    <Container
      label={label}
      style={{ background: background[type] }}
      size="small"
    />
  );
};

export default UserTypeChip;
