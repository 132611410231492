import React from 'react';

import BackButton from '../BackButton';
import { Container } from './styles';

type BackButtonProps = {
  redirectTo: string;
  resource: string;
};

type SubtitleProps = {
  text: string;
  isMobile?: boolean;
  backButton?: BackButtonProps;
};

const Subtitle = ({ text, isMobile = false, backButton }: SubtitleProps) => {
  return (
    <div style={{ display: 'flex', flex: 1, gap: '2rem' }}>
      {backButton && (
        <BackButton
          redirectTo={backButton.redirectTo}
          resource={backButton.resource}
          style={{ marginTop: 8 }}
        />
      )}
      <Container isMobile={isMobile}>
        <p>{text}</p>
      </Container>
    </div>
  );
};

export default Subtitle;
