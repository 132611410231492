import { Button as muiButton } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Button = styled(muiButton)<{ isMobile: boolean }>((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.isMobile
    ? theme.colors.background
    : theme.colors.danger,
  color: props.isMobile ? theme.colors.danger : theme.colors.white,
  border: props.isMobile ? `1px solid ${theme.colors.danger}` : '0px',
  boxShadow: 'none',
}));
