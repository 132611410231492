import { CreditPackageClient } from '@sinagro/shared';

export const formatChoices = (choices: CreditPackageClient[]) => {
  const formattedChoices: CreditPackageClient[] = choices.map((item) => {
    return {
      id: item.id,
      name: `${item.name} - ${item.id}`,
      document: item.document,
      children: item.children,
    };
  });

  return formattedChoices;
};

export const isAValidFilter = (
  newFilter: string,
  filter: string,
  choices: CreditPackageClient[]
) => {
  if (filter.length > 1 && newFilter === '') {
    return false;
  }
  const hasFilterEqualFormattedChoice = choices.filter(
    (item) => item.name === newFilter
  );
  return !hasFilterEqualFormattedChoice.length;
};
