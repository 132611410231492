/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, Button } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Logout, useAuthProvider, useGetOne } from 'react-admin';
import { UserMenuContextProvider } from 'src/providers/userMenu';

import { UserInfo, UserInfoMenu, UserName, UserRole } from './styles';
import { AnchorOrigin, TransformOrigin, getIcon } from './utils';

type UserMenuProps = { id?: string | number };

const UserMenu = ({ id }: UserMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { data: user, isLoading: userLoading } = useGetOne('user', { id });

  const authProvider = useAuthProvider();
  const children = authProvider ? <Logout /> : null;

  const handleMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const context = useMemo(() => ({ onClose: handleClose }), [handleClose]);
  if (!children) return null;
  const open = Boolean(anchorEl);

  return (
    <div>
      <Button color="inherit" onClick={handleMenu}>
        <UserInfoMenu>
          {!userLoading && user && (
            <UserInfo>
              <UserName>{user.name.split(' ')[0]}</UserName>
              <UserRole>{user.rbacRole.name}</UserRole>
            </UserInfo>
          )}
          {userLoading ? getIcon() : getIcon(user.name)}
        </UserInfoMenu>
      </Button>
      <UserMenuContextProvider value={context}>
        <Menu
          id="menu-appbar"
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={AnchorOrigin}
          transformOrigin={TransformOrigin}
          open={open}
          onClose={handleClose}
        >
          {children}
        </Menu>
      </UserMenuContextProvider>
    </div>
  );
};

export default UserMenu;
