import { createTheme } from '@mui/material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { defaultTheme } from 'react-admin';

export const appTheme = {
  ...defaultTheme,
  palette: {
    primary: { main: theme.colors.primary },
    secondary: { main: theme.colors.primary },
    background: { default: theme.colors.background },
    text: { primary: theme.colors.text, secondary: theme.colors.textSecondary },
    common: { black: theme.colors.text },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        padding: 0,
        color: theme.colors.secondary,
      },
    },
    MuiFormLabel: { root: { color: theme.colors.secondary } },
    MuiInputLabel: { root: { fontSize: theme.fontSize.lg } },
    MuiInputBase: { root: { fontSize: theme.fontSize.lg } },
    MuiSvgIcon: {
      colorPrimary: { color: theme.colors.primary },
      colorSecondary: { color: theme.colors.white },
    },
  },
  typography: {
    fontFamily: theme.fontFamily,
    h6: {
      fontSize: theme.fontSize.lg,
      fontWeight: theme.fontWeight.regular,
    },
    h5: {
      fontSize: theme.fontSize.xl,
      fontWeight: theme.fontWeight.regular,
    },
    h4: {
      fontSize: theme.fontSize.lg,
      fontWeight: theme.fontWeight.semibold,
    },
    h3: {
      fontSize: theme.fontSize.xl,
      fontWeight: theme.fontWeight.semibold,
    },
    h2: {
      fontSize: theme.fontSize.xxl,
      fontWeight: theme.fontWeight.semibold,
    },
    h1: {
      fontSize: theme.fontSize.xxxl,
      fontWeight: theme.fontWeight.semibold,
    },
    body1: {
      fontSize: theme.fontSize.md,
      fontWeight: theme.fontWeight.regular,
    },
    body2: {
      fontSize: theme.fontSize.sm,
      fontWeight: theme.fontWeight.regular,
    },
  },
};
export const customTheme = createTheme(appTheme);
