import { Grid } from '@mui/material';
import { styled, css } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Header = styled(Grid)<{ isMobile: boolean }>`
  display: flex;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: block;
      flex-direction: column;
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid ${theme.colors.primary};
    `}
`;
