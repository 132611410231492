import { CircularProgress, Button as ButtonMUI } from '@mui/material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import React from 'react';

type ButtonProps = {
  text: string;
  loading?: boolean;
  onClick?: () => void;
};

const Button = ({ text, loading = false, onClick }: ButtonProps) => {
  return (
    <ButtonMUI
      size="large"
      variant="contained"
      type="submit"
      disabled={loading}
      style={{
        background: theme.colors.quaternary,
        width: '100%',
        color: theme.colors.white,
      }}
      onClick={onClick}
    >
      {text}
      {loading && (
        <CircularProgress
          size={16}
          style={{ color: theme.colors.white, marginLeft: 8 }}
        />
      )}
    </ButtonMUI>
  );
};

export default Button;
