import React from 'react';
import { useSidebarState, useTranslate } from 'react-admin';
import Icon from 'src/assets/icon.png';
import Logo from 'src/assets/logo.png';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { CustomSidebarToggleButton } from '../CustomSidebarToogleButton';
import { LogoContainer, LogoText, SidebarHeaderContainer } from './styles';

const SidebarHeader = () => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const [open, setOpen] = useSidebarState();

  return (
    <SidebarHeaderContainer $isOpen={open}>
      {
        <LogoContainer to="/home">
          <img src={open ? Logo : Icon} />
          {open && <LogoText>{t('common.logo')}</LogoText>}
        </LogoContainer>
      }
      {isMobile && <CustomSidebarToggleButton open={open} setOpen={setOpen} />}
    </SidebarHeaderContainer>
  );
};

export default SidebarHeader;
