import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  DefaultThemeColorsType,
  defaultTheme as theme,
} from '@sinagro/frontend-shared/themes';
import { Link } from 'react-router-dom';

export const Container = styled(Grid)``;

export const Header = styled(Grid)<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white};
  padding: ${({ $isMobile }) => ($isMobile ? 1 : 2)}rem;
  border-bottom: 1px solid ${theme.colors.border};
  padding-top: ${({ $isMobile }) => ($isMobile ? 1.5 : 2.5)}rem;
  gap: 1rem;
`;

export const Title = styled(Typography)<{ $color: DefaultThemeColorsType }>`
  font-size: ${theme.fontSize.big}px;
  font-weight: ${theme.fontWeight.bold};
  color: ${({ $color }) => theme.colors[$color]};
`;

export const Description = styled(Typography)`
  font-size: ${theme.fontSize.lg}px;
  color: ${theme.colors.textSecondary};
`;

export const CardsContainer = styled(Grid)<{ $isMobile: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  padding: ${({ $isMobile }) => ($isMobile ? 1 : 2)}rem;

  @media (max-width: 1366px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 1rem;
  gap: 0.5rem;
  align-items: center;

  cursor: pointer;
  text-decoration: none;

  svg {
    color: ${theme.colors.quaternary};
  }

  :hover {
    filter: brightness(0.96);
    transition: 0.2s;
  }

  @media (max-width: 480px) {
    padding: 1rem 0.5rem;
  }
`;

export const CardText = styled(Typography)`
  font-size: ${theme.fontSize.md}px;
  color: ${theme.colors.text};
  text-align: center;
`;
