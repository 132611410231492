/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchUtils } from 'ra-core';

import { auth } from '../config/firebase';
// fetchUtils.fetchJson receives this parameter
const customHttpClient = async (url: any, options: fetchUtils.Options = {}) => {
  const user = await auth.currentUser;
  if (user) {
    const tokenResult = await user.getIdTokenResult();
    if (tokenResult && tokenResult.token) {
      const requestHeaders = options.headers
        ? new Headers(options.headers)
        : (new Headers({
            Accept: 'application/json',
          }) as Headers);
      requestHeaders.set('Authorization', `Bearer ${tokenResult.token}`);
      options.headers = requestHeaders;
    }
  }
  return await fetchUtils.fetchJson(url, options);
};
export default customHttpClient;
