import React from 'react';
import { List, useTranslate } from 'react-admin';
import ResourceContainer from 'src/components/ResourceContainer';
import Subtitle from 'src/components/Subtitle';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { CreditPackageListFilters } from './filters';
import { ListCreditDocumentPackages } from './list';
import { CreditPackageListActions } from './listActions';
import { MobileListCreditDocumentPackages } from './mobileList';
import { Container, FilterButton, Header } from './styles';

export const CreditPackageList = () => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  return (
    <ResourceContainer
      title={t('resources.credit-document-package.list.title')}
    >
      <List
        actions={<CreditPackageListActions />}
        filters={CreditPackageListFilters()}
        exporter={false}
        sx={{
          paddingBottom: 16,
          '.MuiToolbar-gutters': {
            minHeight: '0px !important',
          },
        }}
      >
        <Container isMobile={isMobile}>
          <Header>
            <Subtitle
              text={t('resources.credit-document-package.list.subtitle')}
              isMobile={isMobile}
            />
            {isMobile && (
              <FilterButton
                filters={CreditPackageListFilters()}
                style={{ marginBottom: '1.2rem' }}
              />
            )}
          </Header>
          {isMobile ? (
            <MobileListCreditDocumentPackages />
          ) : (
            <ListCreditDocumentPackages />
          )}
        </Container>
      </List>
    </ResourceContainer>
  );
};
