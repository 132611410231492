import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import { ChoicesProps } from 'src/components/ClientSearchAutocomplete/utils';
import customHttpClient from 'src/components/CustomHttpClient';
import { SeeAsContext } from 'src/contexts/seeAs';
import { identifyError } from 'src/utils/error';

type SeeAsProviderProps = {
  children: React.ReactNode;
};

export const SeeAsProvider = ({
  children,
}: SeeAsProviderProps): JSX.Element => {
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [selectedClient, setSelectedClient] = useState<ChoicesProps | null>(
    null
  );
  const registeredClient = selectedClient && selectedClient?.hasRegisteredUser;

  const onChangeClient = (client: ChoicesProps | null) => {
    setSelectedClient(client);
    client?.id && activeSeeAs(client.id);
  };

  const activeSeeAs = async (clientId: string) => {
    setIsLoading(true);
    await customHttpClient(`${apiUrl}/backoffice/client/see-as`, {
      method: 'post',
      body: JSON.stringify({
        clientId: clientId,
      }),
    }).catch((error) => {
      notify(identifyError(error), { type: 'error' });
    });
    setIsLoading(false);
  };

  return (
    <SeeAsContext.Provider
      value={{ isLoading, selectedClient, registeredClient, onChangeClient }}
    >
      {children}
    </SeeAsContext.Provider>
  );
};
