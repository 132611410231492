import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { FileInput as FileInputAdmin } from 'react-admin';

export const FileInput = styled(FileInputAdmin)<{
  isMobile: boolean;
  hasUploadedFile: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: ${({ isMobile }) => (isMobile ? '0' : '0.2rem')};
  margin-bottom: ${({ isMobile }) => (isMobile ? 1.5 : 2)}rem;

  .RaFileInput-dropZone {
    display: ${({ hasUploadedFile }) => (hasUploadedFile ? 'none' : 'block')};
    width: 100% !important;
    background-color: transparent;
    border: 1px dashed #7d7f8e;
    color: #7d7f8e;
    text-decoration: underline;
  }

  .MuiFormHelperText-root {
    display: none;
  }

  .previews {
    width: 100%;
    background-color: ${theme.colors.primaryLight};
    text-align: center;
  }
`;

export const LoadingFile = styled(Grid)<{
  isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  padding-top: ${({ isMobile }) => (isMobile ? '0' : '0.2rem')};
  margin-bottom: ${({ isMobile }) => (isMobile ? 1.5 : 2)}rem;
`;
