export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

export const openInNewTab = () => {
  const link = document.createElement('a');
  link.href = PORTAL_URL!;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
};

export const values = {
  mobile: {
    clientSearchAutocompleteLabel: 'resources.seeAs.searchInstruction',
    supportFormLabel: 'resources.seeAs.supportFormShort',
    width: '100%',
  },
  web: {
    clientSearchAutocompleteLabel: 'resources.seeAs.search',
    supportFormLabel: 'resources.seeAs.supportForm',
    width: 'auto',
  },
};
