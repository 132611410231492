import { Container as ContainerMUI, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)({
  maxWidth: 550,
  display: 'flex',
  width: '100%',
});

export const Content = styled(ContainerMUI)({});

export const Text = styled(Typography)({
  textAlign: 'center',
  color: theme.colors.primary,
  fontWeight: 'normal !important',
  marginBottom: '32px !important',
});
