import React from 'react';
import { ChoicesProps } from 'src/components/ClientSearchAutocomplete/utils';

export type SeeAsType = {
  isLoading: boolean | null;
  selectedClient: ChoicesProps | null;
  registeredClient: boolean | null;
  onChangeClient?: (client: ChoicesProps | null) => void;
};

export const SeeAsContext = React.createContext<SeeAsType>({
  isLoading: null,
  selectedClient: null,
  registeredClient: null,
});

export const useSeeAsContext = (): React.ContextType<
  React.Context<SeeAsType>
> => {
  return React.useContext(SeeAsContext);
};
