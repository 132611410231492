import { Button as MUIButton } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Button = styled(MUIButton)<{
  isMobile: boolean;
  disable: boolean;
}>((props) => ({
  background: props.disabled
    ? theme.colors.disabled
    : props.isMobile
    ? theme.colors.background
    : theme.colors.primary,
  color:
    props.disabled || !props.isMobile
      ? theme.colors.white
      : theme.colors.primary,
  border:
    props.disabled || !props.isMobile
      ? 'none'
      : `1px solid ${theme.colors.primary}`,
  boxShadow: 'none',

  [`&:hover`]: {
    background: props.disabled
      ? theme.colors.disabled
      : props.isMobile
      ? theme.colors.background
      : theme.colors.primary,
  },
}));
