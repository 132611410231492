import { CreditPackageClient } from '@sinagro/shared';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ClientDocumentPackageSearchAutocomplete from 'src/components/ClientDocumentPackageSearchAutocomplete';
type CreditClientAutoCompleteProps = {
  disabled: boolean;
  handleChangeSelectedClient?: (client: CreditPackageClient | null) => void;
};

export const CreditClientAutoComplete = ({
  disabled,
  handleChangeSelectedClient,
}: CreditClientAutoCompleteProps) => {
  const { setValue } = useFormContext();
  const onChangeSelectedClient = (client: CreditPackageClient | null) => {
    setValue('client.name', client?.name || '');
    setValue('client.document', client?.document || '');
    setValue('client.children', client?.children || []);

    handleChangeSelectedClient && handleChangeSelectedClient(client);
  };
  return (
    <ClientDocumentPackageSearchAutocomplete
      setSelectedClient={onChangeSelectedClient}
      label="resources.credit-document-package.create.clientId"
      disabled={disabled}
    />
  );
};
