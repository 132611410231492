import { CreditPackageDivisionEnum } from '@sinagro/shared';
import React from 'react';
import { useTranslate } from 'react-admin';

import { Container } from './styles';
import { background } from './utils';

type CreditDocumentDivisionChipProps = {
  type: CreditPackageDivisionEnum;
};

const CreditDocumentDivisionChip = ({
  type,
}: CreditDocumentDivisionChipProps) => {
  const t = useTranslate();
  const division = Object.values(CreditPackageDivisionEnum);
  const isKnown = division.some((item) => item === type);
  const divisionType = isKnown ? type : 'unknown';
  const label = t(`enums.creditPackageDivisionEnum.${divisionType}`);

  return (
    <Container
      label={label}
      style={{ background: background[divisionType] }}
      size="small"
    />
  );
};

export default CreditDocumentDivisionChip;
