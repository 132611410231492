import { Grid } from '@mui/material';
import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import Title from '../Title';

type ResourceContainerProps = {
  children: React.ReactNode;
  title: string;
  noStyling?: boolean;
};

const ResourceContainer = ({
  children,
  title,
  noStyling = false,
}: ResourceContainerProps) => {
  const isMobile = useIsMobile();
  const value = isMobile ? 16 : 32;
  const style = {
    paddingTop: 16,
    paddingInline: value,
    marginBottom: value,
  };
  const sx =
    isMobile || noStyling
      ? {
          ['.RaList-main, .MuiToolbar-root, .MuiPaper-root']: {
            background: 'transparent !important',
            boxShadow: 'none',
          },
        }
      : null;

  return (
    <Grid style={style} sx={sx}>
      <Title title={title} />
      {children}
    </Grid>
  );
};

export default ResourceContainer;
