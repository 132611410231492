import React from 'react';
import { useTranslate, Create, useNotify, useRedirect } from 'react-admin';
import ResourceContainer from 'src/components/ResourceContainer';

import { CreditForm } from '../Form/creditForm';

export const CreditPackageCreate = () => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <ResourceContainer
      title={t('resources.credit-document-package.create.title')}
      noStyling
    >
      <Create
        mutationOptions={{
          onSuccess: (resp: { id: string }) => {
            notify('resources.credit-document-package.create.mutationSuccess', {
              type: 'success',
            });
            redirect('edit', `credit-document-package/${resp.id}`);
          },
        }}
      >
        <CreditForm />
      </Create>
    </ResourceContainer>
  );
};
