import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import React from 'react';
import { useNotify } from 'react-admin';

import msLogo from '../../assets/microsoft_logo.png';
import { auth } from '../../config/firebase';
import { ActiveDirectoryLoginButton } from './styles';

const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
  prompt: 'consent',
  tenant: String(process.env.REACT_APP_ACTIVE_DIRECTORY_TENANT_ID),
});

interface Props {
  onLoggedSuccessfull: (idToken: string) => void;
}

export const ActiveDirectoryLogin = ({ onLoggedSuccessfull }: Props) => {
  const notify = useNotify();

  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const idToken = await result.user.getIdToken();
        onLoggedSuccessfull(idToken);
      })
      .catch((error) => {
        console.error(error);
        const message =
          'Erro ao fazer login com Active Directory, tente novamente mais tarde';
        notify(message, { type: 'error' });
      });
  };
  return (
    <ActiveDirectoryLoginButton onClick={handleLogin}>
      <img src={msLogo} alt="Microsoft Logo" width={24} />
      LOGIN
    </ActiveDirectoryLoginButton>
  );
};
