import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Container } from './styles';

type TitleProps = {
  title: string;
};

const Title = ({ title }: TitleProps) => {
  const isMobile = useIsMobile();

  return (
    <Container isMobile={isMobile}>
      <p>{title}</p>
    </Container>
  );
};

export default Title;
