import React, { useCallback, useEffect, useState } from 'react';
import { AutocompleteInput, useNotify } from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { useSeeAsContext } from 'src/contexts/seeAs';
import { identifyError } from 'src/utils/error';

import { formatChoices, isAValidFilter, ChoicesProps } from './utils';

type ClientSearchAutocompleteProps = {
  setSelectedClient: (client: ChoicesProps | null) => void;
  label: string;
  groupBy?: string;
};

const ClientSearchAutocomplete = ({
  setSelectedClient,
  label,
  groupBy,
}: ClientSearchAutocompleteProps) => {
  const notify = useNotify();
  const [filter, setFilter] = useState('');
  const [choices, setChoices] = useState<ChoicesProps[]>([]);
  const { selectedClient } = useSeeAsContext();
  const autocompleteChoices = (selectedClient && [selectedClient]) || choices;

  const onChangeClient = (newClient: string) => {
    if (newClient === '') {
      setFilter('');
    }
    const client = choices.filter((item) => item.id === newClient)[0];
    setSelectedClient(client);
  };

  const onChangeFilter = (newFilter: string) => {
    if (isAValidFilter(newFilter, filter, choices)) {
      setFilter(newFilter);
    }
  };

  const fetchClients = useCallback(async () => {
    const groupByStringUrl = groupBy ? `&groupBy=${groupBy}` : '';
    return customHttpClient(
      `${apiUrl}/backoffice/client/see-as?query=${filter}${groupByStringUrl}`,
      { method: 'get' }
    )
      .then((res) => {
        const data = res.json.results;
        const formattedChoices = formatChoices(data);
        setChoices(formattedChoices);
      })
      .catch((error) => {
        notify(identifyError(error), { type: 'error' });
      });
  }, [notify, filter, groupBy]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <AutocompleteInput
      source="search"
      label={label}
      variant="outlined"
      helperText=""
      choices={autocompleteChoices}
      onChange={onChangeClient}
      setFilter={onChangeFilter}
      defaultValue={selectedClient?.id}
      noOptionsText="common.noOption"
    />
  );
};

export default ClientSearchAutocomplete;
