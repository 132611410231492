/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserFirebase } from '@sinagro/frontend-shared/src/types';
import { IUser } from '@sinagro/shared';
import { signInWithCustomToken, signOut } from 'firebase/auth';
import { AuthProvider } from 'react-admin';
import { isPublicRoute } from 'src/utils/route';

import { auth } from '../config/firebase';
import { apiUrl } from './AppDataProvider';
import customHttpClient from './CustomHttpClient';

type User = UserFirebase & {
  uid: string;
};

const authProvider: AuthProvider = {
  login: async (params) => {
    if (params.fromActiveDirectory) {
      await customHttpClient(`${apiUrl}/account/login/active-directory`, {
        method: 'post',
        body: JSON.stringify({
          emailOrPhone: params.username,
          password: params.password,
          token: params.idToken,
        }),
      }).catch((error) => {
        throw new Error(error.message);
      });
      return;
    }
    try {
      const { token } = await customHttpClient(`${apiUrl}/user/login`, {
        method: 'post',
        body: JSON.stringify({
          emailOrPhone: params.username,
          password: params.password,
        }),
      })
        .then((res) => {
          return JSON.parse(res.body);
        })
        .catch((error) => {
          throw new Error(error.message);
        });

      await signInWithCustomToken(auth, token).catch((error) => {
        throw new Error(error.message);
      });
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  getPermissions: async () => {
    if (isPublicRoute()) {
      return [];
    }

    const result = await customHttpClient(`${apiUrl}/backoffice/user/me`, {
      method: 'get',
    });

    const user: IUser = JSON.parse(result.body);
    const userPermissions = user.rbacRole?.permissions || [];

    return userPermissions;
  },
  logout: async () => {
    signOut(auth);
  },
  checkAuth: async () =>
    auth.currentUser ? Promise.resolve() : Promise.reject({ message: false }),
  checkError: async (error) => {
    const { status, message } = error;
    if (status === 401 || status === 403) {
      signOut(auth);
      if (message === 'Autenticação inválida, refaça seu login') {
        return Promise.reject({ message: false });
      }
    }
  },
  getIdentity: async () => {
    const user = auth.currentUser;
    if (user === null) {
      return { id: '' };
    }
    const { uid, displayName } = user as User;
    return { id: uid, fullName: displayName };
  },
};

export default authProvider;
