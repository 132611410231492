import { analytics } from '@sinagro/frontend-shared/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { auth } from 'src/config/firebase';
import { UserAuthContext } from 'src/contexts/userAuth';
import Loading from 'src/pages/Loading';

type UserAuthProviderProps = {
  children: React.ReactNode;
};

export const UserAuthProvider = ({
  children,
}: UserAuthProviderProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    if (userId) {
      analytics().setUserId(userId);
    }
  }, [userId]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        auth.updateCurrentUser(user);
      } else {
        signOut(auth);
      }
      setLoading(false);
    });
  }, []);
  return (
    <UserAuthContext.Provider value={{ loading }}>
      {loading ? <Loading /> : children}
    </UserAuthContext.Provider>
  );
};
