import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { Datagrid as DatagridAdmin } from 'react-admin';
import {
  FilterButton as FilterButtonRA,
  TopToolbar as raTopToolbar,
} from 'react-admin';

export const Datagrid = styled(DatagridAdmin)({
  [`& th`]: {
    fontWeight: 'bold',
  },
});

export const TopToolbar = styled(raTopToolbar)({
  display: 'flex',
  gap: 16,
  height: 37,
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  marginBottom: 8,
});

export const FilterButton = styled(FilterButtonRA)`
  display: flex;
  font-weight: bold;
  border-radius: 4px;
  min-height: 33.5px;
  align-items: center;

  .MuiButton-text {
    color: ${theme.colors.text};
  }
`;
