import {
  Grid,
  Typography,
  Chip,
  Button as ButtonMUI,
  IconButton,
} from '@mui/material';
import { styled, css } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)<{ isMobile: boolean }>`
  display: flex;
  width: 240px;
  flex-direction: column;
  padding: 24px 16px;
  gap: 16px;
  background: ${theme.colors.backoffice.lightGray};

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      background: ${theme.colors.white};
      padding: 16px 0px;
    `}
`;

export const SupportContent = styled(Grid)({
  fontSize: theme.fontSize.sm,
});

export const Text = styled(Typography)({
  color: theme.colors.text,
  fontWeight: 500,
});

export const RegisteredTag = styled(Chip)({
  background: theme.colors.backoffice.green,
  fontWeight: 500,
  width: 'fit-content',
});

export const NoRegisteredTag = styled(Chip)({
  background: theme.colors.tertiary,
  fontWeight: 500,
  width: 'fit-content',
});

export const Wrapper = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  height: 32,
});

export const Button = styled(ButtonMUI)({
  background: theme.colors.backoffice.blue,
  color: theme.colors.white,
  marginRight: 8,
});

export const InfoButton = styled(IconButton)({
  color: theme.colors.text,
});

export const Info = styled(Typography)({
  color: theme.colors.textSecondary,
});

export const InfoOkButton = styled(ButtonMUI)({
  width: '100%',
  background: theme.colors.primary,
  color: theme.colors.white,
  marginTop: 16,

  [`:hover`]: {
    background: theme.colors.primaryDark,
  },
});
