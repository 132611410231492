import { CircularProgress as Loader } from '@mui/material';
import { CreditPackage } from '@sinagro/shared';
import React, { useEffect, useState } from 'react';
import { TextInput, useNotify, useRefresh, useTranslate } from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { identifyError } from 'src/utils/error';
import { formatCurrencyToValue, formatValueToCurrency } from 'src/utils/format';

import ConfirmationModal from './confirmationModal';
import {
  Container,
  RequiredField,
  RequestButton,
  RequestContainer,
  RequestsHistory,
} from './styles';
import { formatCreditRequests } from './utils';

type CreditRequestProps = {
  creditRequests: CreditPackage['creditRequests'];
  packageId: string;
};

const CreditRequest = ({ creditRequests, packageId }: CreditRequestProps) => {
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const isMobile = useIsMobile();
  const maxWidth = isMobile ? '100%' : 256;
  const hasCreditRequests = (creditRequests || []).length > 0;
  const paddingBottom = hasCreditRequests ? 24 : 8;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [creditRequestValue, setCreditRequestValue] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValue = hasCreditRequests
    ? formatValueToCurrency(creditRequests![0].valueInCents.toString())
    : '';

  const formatValue = (value: string) => {
    const formattedValue = formatValueToCurrency(value);
    const parsedValue = formatCurrencyToValue(formattedValue);
    setCreditRequestValue(parsedValue);
    return formattedValue;
  };

  const handleOpenModal = () => {
    if (creditRequestValue === 0) {
      return setIsEmpty(true);
    }
    setIsOpenModal(true);
  };

  const newCreditRequest = async () => {
    setIsOpenModal(false);
    setIsLoading(true);
    await customHttpClient(
      `${apiUrl}/backoffice/credit-package/${packageId}/additional-credit`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          valueInCentsBrl: creditRequestValue,
        }),
      }
    )
      .then(() => {
        notify(t('resources.credit-document-package.creditRequest.success'), {
          type: 'success',
        });
        refresh();
      })
      .catch((error) => {
        notify(identifyError(error), { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (creditRequestValue > 0 && isEmpty) {
      setIsEmpty(false);
    }
  }, [creditRequestValue, isEmpty]);

  return (
    <Container style={{ paddingBottom }}>
      <RequestContainer>
        <TextInput
          source="creditRequestValue"
          type="text"
          label="resources.credit-document-package.creditRequest.value"
          variant="outlined"
          format={formatValue}
          onChange={setCreditRequestValue}
          defaultValue={defaultValue}
          fullWidth
          style={{ maxWidth }}
        />
        <RequestButton
          size="small"
          type="button"
          variant="contained"
          onClick={handleOpenModal}
          disabled={isLoading}
          startIcon={isLoading && <Loader size={16} />}
        >
          {t('resources.credit-document-package.creditRequest.request')}
        </RequestButton>
      </RequestContainer>
      {isEmpty && (
        <RequiredField>
          {t('resources.credit-document-package.creditRequest.requiredField')}
        </RequiredField>
      )}
      {hasCreditRequests && (
        <RequestsHistory>
          {creditRequests?.map((request) => (
            <>
              {formatCreditRequests(request, t)}
              <br />
            </>
          ))}
        </RequestsHistory>
      )}
      <ConfirmationModal
        onClose={() => setIsOpenModal(false)}
        onConfirm={newCreditRequest}
        open={isOpenModal}
      />
    </Container>
  );
};

export default CreditRequest;
