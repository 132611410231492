import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
});

export const Sidebar = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  background: theme.colors.primary,
  height: '100%',
  justifyContent: 'center',
});

export const ContentSidebar = styled('div')({
  maxWidth: 390,
  padding: 24,
});

export const Content = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  alignItems: 'center',
});

export const Image = styled('img')({
  height: 61,
  marginBottom: 8,
});

export const TitleSidebar = styled(Typography)({
  color: theme.colors.white,
  fontWeight: 'normal !important',
  fontSize: '48px !important',
  marginBottom: '32px !important',
});

export const Text = styled(Typography)({
  color: theme.colors.white,
  fontWeight: 'normal !important',
});

export const Title = styled(Typography)({
  maxWidth: 480,
  textAlign: 'center',
  color: theme.colors.primary,
  fontWeight: 'normal !important',
  marginBottom: '32px !important',
});
