/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HomeTwoTone,
  HomeOutlined,
  FolderSharedTwoTone,
  FolderSharedOutlined,
  GroupTwoTone,
  GroupOutlined,
  ManageAccountsTwoTone,
  ManageAccountsOutlined,
  CreditCardTwoTone,
  CreditCardOutlined,
} from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { Permissions } from '@sinagro/shared';
import React from 'react';
import { CreditPackageCreate } from 'src/resources/creditDocumentPackage/CreditPackageCreate';
import { CreditPackageEdit } from 'src/resources/creditDocumentPackage/CreditPackageEdit';
import { CreditPackageList } from 'src/resources/creditDocumentPackage/CreditPackageList';
import { Home } from 'src/resources/home';
import { PortalUserList } from 'src/resources/portal/user/UserList';
import { SeeAs } from 'src/resources/seeAs';
import { UserEdit } from 'src/resources/user/UserEdit';
import { UserList } from 'src/resources/user/UserList';
import { canAccess, canAccessManyPermissions } from 'src/utils/permission';

type SvgIconComponent = typeof SvgIcon;

type MenuItemProps = {
  name: string;
  path: string;
  menuIcon: SvgIconComponent;
  cardIcon: JSX.Element;
  hideCard: boolean;
  hasPermission: boolean;
  list?: JSX.Element;
  create?: JSX.Element;
  edit?: JSX.Element;
};

export const items = (permissions: any): MenuItemProps[] => [
  {
    name: 'home',
    path: '/home',
    menuIcon: HomeTwoTone,
    cardIcon: <HomeOutlined />,
    hideCard: true,
    hasPermission: true,
    list: <Home />,
  },
  {
    name: 'seeAs',
    path: '/seeAs',
    menuIcon: FolderSharedTwoTone,
    cardIcon: <FolderSharedOutlined />,
    hideCard: false,
    hasPermission: canAccess(Permissions.Client_See_As_Full, permissions),
    list: <SeeAs />,
  },
  {
    name: 'portal/user',
    path: '/portal/user',
    menuIcon: GroupTwoTone,
    cardIcon: <GroupOutlined />,
    hideCard: false,
    hasPermission: canAccess(Permissions.User_Portal_Read, permissions),
    list: <PortalUserList />,
  },
  {
    name: 'user',
    path: '/user',
    menuIcon: ManageAccountsTwoTone,
    cardIcon: <ManageAccountsOutlined />,
    hideCard: false,
    hasPermission: canAccess(Permissions.User_Read, permissions),
    list: <UserList />,
    create: <></>,
    edit: <UserEdit />,
  },
  {
    name: 'credit-document-package',
    path: '/credit-document-package',
    menuIcon: CreditCardTwoTone,
    cardIcon: <CreditCardOutlined />,
    hideCard: false,
    hasPermission: canAccessManyPermissions(
      [
        Permissions.CreditPackage_Full,
        Permissions.CreditPackage_Read,
        Permissions.CreditPackage_Write,
        Permissions.CreditPackageDocument_Approve,
      ],
      permissions
    ),
    list: <CreditPackageList />,
    create: <CreditPackageCreate />,
    edit: <CreditPackageEdit />,
  },
];
