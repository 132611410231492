import { BackofficeListUserFromPortalDTO } from '@sinagro/shared';
import { Permissions } from '@sinagro/shared';
import React from 'react';
import {
  DateField,
  EmailField,
  ExportButton,
  FilterForm,
  FunctionField,
  List,
  TextField,
  usePermissions,
  useTranslate,
} from 'react-admin';
import ResourceContainer from 'src/components/ResourceContainer';
import UserTypeChip from 'src/components/UserTypeChip';
import { canAccess } from 'src/utils/permission';

import { filters } from './filters';
import { Datagrid, FilterButton, TopToolbar } from './styles';
import { exporter } from './utils';

export const PortalUserList = () => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const canExportUsers = canAccess(Permissions.User_Export, permissions || []);

  return (
    <ResourceContainer title={t('resources.portal/user.title')}>
      <List
        exporter={exporter}
        filters={<FilterForm style={{ marginBottom: 8 }} filters={filters} />}
        actions={
          <TopToolbar>
            <FilterButton filters={filters} disableSaveQuery />
            {canExportUsers ? <ExportButton /> : <></>}
          </TopToolbar>
        }
      >
        <Datagrid bulkActionButtons={<></>}>
          <TextField source="name" />
          <EmailField source="email" />
          <TextField source="phoneNumber" sortable={false} />
          <FunctionField
            source="hasTorqFarmRelated"
            sortable={false}
            render={(record: BackofficeListUserFromPortalDTO) =>
              t(
                `resources.portal/user.hasTorqFarmRelated.${record.hasTorqFarmRelated}`
              )
            }
          />
          <FunctionField
            sortable={false}
            source="type"
            render={(record: BackofficeListUserFromPortalDTO) => (
              <UserTypeChip type={record.type} />
            )}
          />
          <FunctionField
            sortable={false}
            source="mainClientId"
            render={(record: BackofficeListUserFromPortalDTO) =>
              record.mainClientId || '-'
            }
          />
          <FunctionField
            sortable={false}
            source="mainCrmClientId"
            render={(record: BackofficeListUserFromPortalDTO) =>
              record.mainCrmClientId || '-'
            }
          />
          <DateField source="createdAt" />
        </Datagrid>
      </List>
    </ResourceContainer>
  );
};
