/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreditPackage, IRelatedSalesTeam } from '@sinagro/shared';
import { stringify } from 'query-string';
import { DataProvider } from 'ra-core';
import { withLifecycleCallbacks } from 'react-admin';
import {
  CreditDocument,
  DocumentValueType,
  getRelatedClientFromClientById,
  handleUploadFile,
} from 'src/resources/creditDocumentPackage/Form/utils';
import { v4 } from 'uuid';

import httpClient from './CustomHttpClient';

type DocumentValueTypeWithSrcFile = {
  id?: string;
  description: string;
  additionalText?: string;
  validUntil?: string;
  relatedClient?: string;
  restrictVisibility?: string;
  file?: {
    id?: string;
    src: string;
    title: string;
    rawFile: Blob;
  };
  rejectionReason?: string;
};

export const apiUrl = process.env.REACT_APP_BASE_URL;

const AppDataProvider = withLifecycleCallbacks(
  {
    getList: async (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const query = {
        limit: perPage,
        page: page - 1,
        sortOrder: order,
        sort: field,
        ...params.filter,
      };

      const url = `${apiUrl}/backoffice/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ json }) => ({
        data: json.results.map((result: any) => ({
          ...result,
        })),
        total: json.total,
      }));
    },
    getMany: (resource, params) => {
      const query = {
        id: params.ids.map((id) => id),
        limit: params.ids.length,
        page: 0,
      };
      const url = `${apiUrl}/backoffice/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({
        data: json.results.map((result: any) => ({
          ...result,
        })),
      }));
    },
    getOne: (resource, params) =>
      httpClient(`${apiUrl}/backoffice/${resource}/${params.id}`).then(
        ({ json }) => ({ data: json })
      ),
    create: (resource, params) =>
      httpClient(`${apiUrl}/backoffice/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      })),
    update: (resource, params) => {
      const isCreditDocumentPackageResource =
        resource === 'credit-document-package';
      const method = isCreditDocumentPackageResource ? 'PUT' : 'PATCH';
      const paramId = isCreditDocumentPackageResource ? '' : `/${params.id}`;
      return httpClient(`${apiUrl}/backoffice/${resource}${paramId}`, {
        method,
        body: JSON.stringify(params.data),
      }).then(() => {
        const data = { ...params.data, id: params.id };
        return { data };
      });
    },
    delete: (resource, params) =>
      httpClient(`${apiUrl}/backoffice/${resource}/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json })),
  } as DataProvider,
  [
    {
      resource: 'user',
      beforeGetList: async (params) => {
        const filter = params?.filter;
        if (filter.name) filter.name = `%${filter.name}%`;
        if (filter.email) filter.email = `%${filter.email}%`;

        return {
          ...params,
          filter,
        };
      },
      beforeUpdate: async (params) => {
        const { data } = params;

        const isConsultant = data.rbacRole.isDigitalConsultant;
        const isCreditAnalyst = data.rbacRole.isCreditAnalyst;
        const relatedSalesTeam = data.relatedSalesTeam || [];
        const sanitizedRelatedSalesTeam: IRelatedSalesTeam[] =
          relatedSalesTeam.map((salesTeam: IRelatedSalesTeam) => ({
            id: salesTeam.id,
            name: salesTeam.name,
          }));
        return {
          ...params,
          data: {
            rbacRoleId: data.rbacRoleId,
            name: data.name,
            digitalConsultantRelatedClients: isConsultant
              ? data.digitalConsultantRelatedClients
              : [],
            relatedSalesTeam: isCreditAnalyst ? sanitizedRelatedSalesTeam : [],
          },
        };
      },
    },
    {
      resource: 'credit-document-package',
      afterGetOne: async (params) => {
        const values: CreditPackage = params.data;
        return {
          data: {
            ...params.data,
            creditLimitInCentsInteger: parseInt(
              values.creditLimitInCents.toString()
            ).toString(),
            documents: values.documents.map((document) => ({
              ...document,
              relatedClient: document.relatedClient?.id || undefined,
              file: document.file
                ? {
                    ...document.file,
                    src: document.file?.url,
                  }
                : undefined,
            })),
          },
        };
      },
      beforeGetList: async (params) => {
        const filter = params?.filter;
        if (filter.clientName)
          return {
            ...params,
            filter: {
              ...filter,
              clientName: `%${filter.clientName}%`,
            },
          };
        return {
          ...params,
        };
      },
      beforeCreate: async (params) => {
        const values = params.data;
        const valuesDocuments: DocumentValueType[] = values.documents.map(
          (document: DocumentValueTypeWithSrcFile) => ({
            ...document,
            relatedClient: getRelatedClientFromClientById(
              values.client,
              document.relatedClient
            ),
            additionalText: document.additionalText || undefined,
          })
        );
        const documentPackageId = v4();
        const files: CreditDocument[] = [];
        if (valuesDocuments) {
          const processedDocuments = await handleUploadFile({
            documents: valuesDocuments,
            documentPackageId,
          });
          files.push(...processedDocuments);
        }
        const creditLimitInCents = Number(
          values.creditLimitInCentsInteger.toString().replace(/[.,]+/g, '')
        );
        return {
          data: {
            ...values,
            id: documentPackageId,
            documents: files,
            requestedAt: new Date(),
            cultivation: values.cultivation || undefined,
            creditLimitInCents,
            creditLimitInCentsInteger: undefined,
          },
        };
      },
      beforeUpdate: async (params) => {
        delete params.data.relatedQuotes;
        delete params.data.deletedAt;
        delete params.data.creditRequests;
        delete params.data.creditRequestValue;

        const values = params.data;
        const files: CreditDocument[] = [];
        const documentsFileFromSrcToUrl: DocumentValueType[] =
          values.documents.map((document: DocumentValueTypeWithSrcFile) => {
            delete document.restrictVisibility;
            delete document.rejectionReason;
            return {
              ...document,
              relatedClient: getRelatedClientFromClientById(
                values.client,
                document.relatedClient
              ),
              uploadedAt: undefined,
              uploadedBy: undefined,
              'uploadedBy.userName': undefined,
              status: undefined,
              internalStatus: undefined,
              rejections: undefined,
              validUntil: document.validUntil || undefined,
              approval: undefined,
              additionalText: document.additionalText || undefined,
              file: {
                ...document.file,
                url: document.file?.src,
                src: undefined,
              },
            };
          });
        if (values.documents) {
          const processedDocuments = await handleUploadFile({
            documents: documentsFileFromSrcToUrl,
            documentPackageId: String(params.id),
          });
          files.push(...processedDocuments);
        }
        const creditLimitInCents = Number(
          values.creditLimitInCents.toString().replace(/[.,]+/g, '')
        );
        return {
          ...params,
          data: {
            ...values,
            _id: undefined,
            status: undefined,
            internalStatus: undefined,
            isDeleted: undefined,
            createdAt: undefined,
            creditAnalyst: undefined,
            updatedAt: undefined,
            uploadedBy: undefined,
            __v: undefined,
            id: params.id,
            documents: files,
            requestedAt: new Date(),
            cultivation: values.cultivation || undefined,
            harvestReference: values.harvestReference || undefined,
            plantingArea: undefined,
            creditLimitInCents,
            creditLimitInCentsInteger: undefined,
          },
        };
      },
    },
  ]
);

export default AppDataProvider;
