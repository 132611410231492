import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)({
  display: 'flex',
  width: '100%',
  height: '100vh',
  background: theme.colors.background,
  alignItems: 'center',
  justifyContent: 'center',
});

export const Loader = styled(CircularProgress)({
  color: theme.colors.primary,
});
