import React from 'react';
import { useTranslate } from 'react-admin';
import Subtitle from 'src/components/Subtitle';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Header } from './styles';

export const CreateCreditPackageHeader = () => {
  const t = useTranslate();
  const isMobile = useIsMobile();

  return (
    <Header isMobile={isMobile}>
      <Subtitle
        text={t('resources.credit-document-package.create.subtitle')}
        isMobile={isMobile}
        backButton={{
          redirectTo: 'list',
          resource: 'credit-document-package',
        }}
      />
    </Header>
  );
};
