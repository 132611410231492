import { CreditPackageStatusEnum } from '@sinagro/shared';
import React from 'react';
import { useTranslate } from 'react-admin';

import { Container } from './styles';
import { background } from './utils';

type CreditDocumentStatusChipProps = {
  type: CreditPackageStatusEnum;
};

const CreditDocumentStatusChip = ({ type }: CreditDocumentStatusChipProps) => {
  const t = useTranslate();
  const label = t(`enums.creditDocumentStatusEnum.${type}`);
  return (
    <Container
      label={label}
      style={{ background: background[type] }}
      size="small"
    />
  );
};

export default CreditDocumentStatusChip;
