import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const ActiveDirectoryLoginButton = styled(Button)`
  background: #ffffff !important;
  color: #5e5e5e !important;
  text-transform: none !important;
  border: 1px solid #8c8c8c;
  border-radius: 4px;
  padding: 12px;
  height: 41px;
  &:hover {
    background: #ffffff !important;
    opacity: 0.8 !important;
  }
  margin: auto;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 12px;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;
