import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)({});

export const Label = styled(Typography)({
  fontSize: theme.fontSize.sm,
  color: theme.colors.textSecondary,
});

export const Value = styled(Typography)({
  fontSize: theme.fontSize.md,
  color: theme.colors.text,
});
