/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material';
import React, { useCallback } from 'react';
import {
  FileField,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { patchDocumentFile } from '../utils';
import { LoadingFile, FileInput } from './styles';

type DocumentFilesInputProps = {
  scopedFormData: any;
  setRemoveFileConfirm: (param: any) => void;
  getSource?: (source: string) => string;
  setDocumentName: (name: string) => void;
  setIsRemoveFileModalOpen: (isOpen: boolean) => void;
  isMobile: boolean;
  documentWithFileLoadingId: string | null;
  setIsLoading: (value: string | null) => void;
};

export const DocumentFilesInput = ({
  getSource,
  scopedFormData,
  setDocumentName,
  setRemoveFileConfirm,
  setIsRemoveFileModalOpen,
  isMobile,
  documentWithFileLoadingId,
  setIsLoading,
}: DocumentFilesInputProps) => {
  const refresh = useRefresh();
  const t = useTranslate();
  const notify = useNotify();
  const { setValue } = useFormContext();
  const record = useRecordContext();
  const source = getSource ? getSource('file') : 'file';
  const hasUploadedFile = scopedFormData && getSource && scopedFormData.file;
  const isThisDocumentLoading =
    documentWithFileLoadingId &&
    documentWithFileLoadingId === scopedFormData.id;

  const handleFileChange = useCallback(
    async (file: File | null) => {
      setIsLoading(scopedFormData.id);
      if (!file && !hasUploadedFile) {
        notify('resources.credit-document-package.edit.selectFileFailed', {
          type: 'error',
        });
        setIsLoading(null);
        return;
      }

      if (!scopedFormData.id || !record.id) return;
      patchDocumentFile(scopedFormData.id, record.id.toString(), file)
        .then(() => {
          refresh();
          notify('resources.credit-document-package.edit.patchFileSuccess', {
            type: 'success',
          });
        })
        .catch(() => {
          setValue(source, undefined);
          notify('resources.credit-document-package.edit.patchFileFailed', {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(null);
        });
    },
    [
      hasUploadedFile,
      notify,
      record,
      refresh,
      scopedFormData,
      setIsLoading,
      setValue,
      source,
    ]
  );

  return (
    <>
      {isThisDocumentLoading ? (
        <LoadingFile isMobile={isMobile}>
          <CircularProgress size={20} />
        </LoadingFile>
      ) : (
        <FileInput
          source={source}
          label="resources.credit-document-package.create.file"
          placeholder={t(
            'resources.credit-document-package.create.fileInstructions'
          )}
          validateFileRemoval={() => {
            const promise = new Promise<boolean>((_resolve, reject) => {
              setRemoveFileConfirm({
                delete: async (result: boolean) => {
                  return _resolve(result);
                },
                cancel: reject,
              });
            });
            setDocumentName(scopedFormData.description);
            setIsRemoveFileModalOpen(true);
            return promise.then((result: boolean) => result);
          }}
          accept="image/png, image/jpeg, image/jpg, application/pdf, application/xlsx, application/xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          isMobile={isMobile}
          hasUploadedFile={hasUploadedFile}
          onChange={handleFileChange}
          maxSize={10000000}
        >
          {hasUploadedFile && (
            <FileField
              source="src"
              title={
                hasUploadedFile?.title ||
                scopedFormData?.file?.filename ||
                t('resources.credit-document-package.create.download')
              }
              target="_blank"
              download={true}
            />
          )}
        </FileInput>
      )}
    </>
  );
};
