import { Avatar, PopoverOrigin } from '@mui/material';
import React from 'react';

import { DefaultAvatar } from './styles';
import { getNameInitials } from './userUtils';

export const getIcon = (name?: string | undefined) => {
  if (name) {
    const result = getNameInitials(name);

    return <Avatar style={{ width: 36, height: 36 }}>{result}</Avatar>;
  }

  return <DefaultAvatar />;
};

export const AnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

export const TransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
