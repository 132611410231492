import * as React from 'react';
import { useGetIdentity, useSidebarState } from 'react-admin';

import { CustomSidebarToggleButton } from '../CustomSidebarToogleButton';
import { ContainerAppBar, StyledAppBar } from './styles';
import UserMenu from './UserMenu';

const CustomAppBar = () => {
  const [open, setOpen] = useSidebarState();
  const { data, isLoading: identityLoading } = useGetIdentity();

  return (
    <StyledAppBar elevation={0}>
      <ContainerAppBar>
        <CustomSidebarToggleButton open={open} setOpen={setOpen} />
        {!identityLoading && <UserMenu id={data?.id} />}
      </ContainerAppBar>
    </StyledAppBar>
  );
};

export default CustomAppBar;
