import React from 'react';

import ImageError from '../../assets/error.png';
import { Container, Image, Link, Text, Title } from './styles';

const ErrorFallback = () => {
  const bkoURL = process.env.REACT_APP_BKO_URL;

  return (
    <Container>
      <Image src={ImageError} />
      <Title>Ops! Algo deu errado....</Title>
      <Text>Por favor, tente novamente mais tarde.</Text>
      <Link to={`${bkoURL}`}>Voltar</Link>
    </Container>
  );
};

export default ErrorFallback;
