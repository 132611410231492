import { Button } from '@mui/material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { CreditPackageClient } from '@sinagro/shared';
import React, { useState } from 'react';
import {
  useTranslate,
  Form,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext,
  FormDataConsumer,
} from 'react-admin';
import { CurrencyInput } from 'src/components/CurrencyInput';
import { DeleteButtonWithConfirmation } from 'src/components/DeleteButtonWithConfirmation';
import { useCreditDocumentPackageContext } from 'src/contexts/creditDocumentPackage';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { formatDate } from 'src/utils/format';

import { CreateCreditPackageHeader } from '../CreditPackageCreate/createCreditPackageHeader';
import { EditCreditPackageHeader } from '../CreditPackageEdit/editCreditPackageHeader';
import { CreditClientAutoComplete } from './creditClientAutoComplete';
import CreditRequest from './CreditRequest';
import { DeleteDialogue } from './dialogue';
import { DocumentsInput } from './DocumentsInput';
import DownloadAllDocuments from './DownloadAllDocuments';
import { Actions, CreditPackageInfo, Footer, Group, Row } from './styles';
import { getDateFormattedAsYearMonthDay } from './utils';

type CreditFormsProps = {
  isEdit?: boolean;
};

export const CreditForm = ({ isEdit }: CreditFormsProps) => {
  const t = useTranslate();
  const { selectedClient } = useCreditDocumentPackageContext();
  const [client, setClient] = useState<CreditPackageClient | null>(null);
  const [hasSelectedClient, setHasSelectedClient] = useState(false);
  const showClientData = isEdit || hasSelectedClient;
  const record = useRecordContext();
  const isMobile = useIsMobile();
  const maxWidth = isMobile ? '100%' : 256;
  const bottom = isEdit ? 0 : 6;

  const handleChangeSelectedClient = (client: CreditPackageClient | null) => {
    const hasClient = client ? true : false;
    setHasSelectedClient(hasClient);
    setClient(client);
  };

  return (
    <>
      <Form>
        <CreditPackageInfo>
          {isEdit ? <EditCreditPackageHeader /> : <CreateCreditPackageHeader />}
          {isEdit && (
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <CreditRequest
                    creditRequests={formData.creditRequests}
                    packageId={formData.id}
                  />
                );
              }}
            </FormDataConsumer>
          )}
          <Group isMobile={isMobile}>
            <CurrencyInput
              source="creditLimitInCentsInteger"
              type="text"
              label="resources.credit-document-package.create.winningQuote"
              variant="outlined"
              disabled={!!isEdit}
              required
              fullWidth
              style={{ maxWidth }}
            />
            <DateInput
              source="dueDate"
              label="resources.credit-document-package.create.dueDate"
              variant="outlined"
              required
              fullWidth
              inputProps={{
                min:
                  isEdit && record
                    ? getDateFormattedAsYearMonthDay(new Date(record.dueDate))
                    : getDateFormattedAsYearMonthDay(new Date()),
              }}
              style={{ maxWidth }}
              format={formatDate}
            />

            <TextInput
              type="text"
              source="responsibleConsultant"
              label="resources.credit-document-package.create.responsibleConsultant"
              variant="outlined"
              value={''}
              fullWidth
              style={{ maxWidth }}
            />
            {(!isEdit || record?.responsibleLivestockConsultant) && (
              <TextInput
                type="text"
                source="responsibleLivestockConsultant"
                label="resources.credit-document-package.create.responsibleLivestockConsultant"
                variant="outlined"
                value={''}
                fullWidth
                style={{ maxWidth }}
              />
            )}
          </Group>

          <Group isMobile={isMobile}>
            <Row>
              {isEdit ? (
                <TextInput
                  source="client.id"
                  variant="standard"
                  fullWidth
                  disabled
                  style={{ maxWidth, bottom }}
                />
              ) : (
                <CreditClientAutoComplete
                  disabled={false}
                  handleChangeSelectedClient={handleChangeSelectedClient}
                />
              )}

              {showClientData && (
                <TextInput
                  type="text"
                  source="client.document"
                  label="resources.credit-document-package.create.clientDocument"
                  variant="standard"
                  fullWidth
                  disabled
                  style={{ maxWidth, bottom }}
                />
              )}
            </Row>

            {showClientData && (
              <TextInput
                type="text"
                source="client.name"
                label="resources.credit-document-package.create.clientName"
                variant="standard"
                fullWidth
                disabled
                style={{ maxWidth, bottom }}
              />
            )}
          </Group>

          <ArrayInput
            source="client.children"
            label={
              selectedClient?.children?.length &&
              selectedClient?.children?.length > 1
                ? 'resources.credit-document-package.create.clientChildren'
                : false
            }
          >
            <SimpleFormIterator
              disableClear
              disableReordering
              disableAdd
              disableRemove
              inline
              fullWidth
            >
              <TextInput
                type="text"
                source="id"
                label="resources.credit-document-package.create.partnerId"
                variant="standard"
                fullWidth
                disabled
                style={{ maxWidth }}
              />

              <TextInput
                type="text"
                source="name"
                label="resources.credit-document-package.create.partnerName"
                variant="standard"
                fullWidth
                disabled
                style={{ maxWidth }}
              />
            </SimpleFormIterator>
          </ArrayInput>

          <Group isMobile={isMobile}>
            <Row>
              <TextInput
                source="cultivation"
                label="resources.credit-document-package.create.cultivation"
                variant="outlined"
                fullWidth
                style={{ margin: 0, maxWidth }}
              />

              <TextInput
                type="text"
                source="harvestReference"
                label="resources.credit-document-package.create.harvestReference"
                variant="outlined"
                fullWidth
                style={{ margin: 0, maxWidth }}
                disabled={isEdit}
              />
            </Row>
          </Group>
        </CreditPackageInfo>

        <DocumentsInput isEdit={!!isEdit} client={client} />

        <Footer isEdit={!!isEdit} isMobile={isMobile}>
          {isEdit && (
            <Actions>
              <DeleteButtonWithConfirmation
                buttonLabel="resources.credit-document-package.delete.button.label"
                modal={{
                  title: 'resources.credit-document-package.delete.modal.title',
                  content: <DeleteDialogue />,
                  buttonCancelLabel:
                    'resources.credit-document-package.delete.modal.cancelLabel',
                  buttonConfirmLabel:
                    'resources.credit-document-package.delete.modal.confirmLabel',
                }}
              />
              <DownloadAllDocuments
                creditPackageId={record.id.toString()}
                documents={record.documents}
              />
            </Actions>
          )}
          <Button
            size="small"
            type="submit"
            variant="contained"
            style={{
              background: theme.colors.backoffice.blue,
              color: theme.colors.white,
              width: isMobile ? '100%' : 'auto',
            }}
          >
            {isEdit
              ? t('resources.credit-document-package.edit.buttonLabel')
              : t('resources.credit-document-package.create.requestDocuments')}
          </Button>
        </Footer>
      </Form>
    </>
  );
};
