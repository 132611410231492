import { CreditPackage } from '@sinagro/shared';
import React from 'react';
import { EditButton, FunctionField, useTranslate } from 'react-admin';
import CreditDocumentGroupedDivisionChips from 'src/components/CreditDocumentDivisionChips';
import CreditDocumentStatusChip from 'src/components/CreditDocumentStatusChip';
import DefaultField from 'src/components/DefaultField';
import { formatDatePTBR } from 'src/utils/format';

import { Card, Column, List, Row } from './styles';

export const MobileListCreditDocumentPackages = () => {
  const t = useTranslate();
  const defaulFieldStyle = { marginTop: 8 };

  return (
    <List
      primaryText={
        <Card>
          <FunctionField
            sortable={false}
            source="internalStatus"
            label="resources.credit-document-package.list.header.status"
            render={(record: CreditPackage | undefined) =>
              record?.internalStatus ? (
                <CreditDocumentStatusChip type={record.internalStatus} />
              ) : (
                '-'
              )
            }
          />
          <FunctionField
            source="client.name"
            sortable={false}
            render={(record: CreditPackage | undefined) => (
              <DefaultField
                label={t(
                  'resources.credit-document-package.list.header.client.name'
                )}
                value={record?.client.name}
                style={defaulFieldStyle}
              />
            )}
          />

          <FunctionField
            sortable={false}
            render={(record: CreditPackage | undefined) => (
              <Row>
                <Column>
                  <DefaultField
                    label={t(
                      'resources.credit-document-package.list.header.client.id'
                    )}
                    value={record?.client.id}
                  />
                  <div>
                    <DefaultField
                      label={t(
                        'resources.credit-document-package.list.header.division'
                      )}
                    />

                    {record?.division && record?.division?.length > 0 ? (
                      <CreditDocumentGroupedDivisionChips
                        divisions={record.division}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </Column>

                <Column>
                  <DefaultField
                    label={t(
                      'resources.credit-document-package.list.header.dueDate'
                    )}
                    value={formatDatePTBR(record?.dueDate)}
                  />
                  <DefaultField
                    label={t(
                      'resources.credit-document-package.list.header.requestedAt'
                    )}
                    value={formatDatePTBR(record?.requestedAt)}
                  />
                </Column>

                <Column style={{ justifyContent: 'flex-end' }}>
                  <EditButton
                    label="resources.credit-document-package.list.header.details"
                    startIcon={false}
                    sx={() => ({
                      backgroundColor: '#1976d2',
                      color: 'white',
                      paddingX: 1,
                      paddingY: 1,
                      ':hover': {
                        backgroundColor: '#6f7479',
                      },
                    })}
                  />
                </Column>
              </Row>
            )}
          />
        </Card>
      }
    />
  );
};
