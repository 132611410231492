import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const CreditPackageInfo = styled(Grid)`
  background: ${theme.colors.white};
  border-radius: 4px;
  margin-left: 2px;
  margin-bottom: 2rem;
  padding: 1rem;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const Group = styled(Grid)<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? 0 : 1)}rem;
`;

export const Row = styled(Grid)`
  max-width: 528px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Footer = styled(Grid)<{ isEdit: boolean; isMobile: boolean }>(
  (props) => ({
    display: 'flex',
    width: '100%',
    justifyContent: props.isEdit ? 'space-between' : 'flex-end',
    marginTop: '2rem',
    alignItems: 'flex-start',
    marginBottom: props.isMobile ? '2rem' : '0',
    gap: '1rem',
    flexDirection: props.isMobile ? 'column' : 'row',
  })
);

export const Text = styled(Typography)({
  textAlign: 'center',
  color: theme.colors.secondary,
  fontWeight: 'normal !important',
  marginBottom: '32px !important',
});

export const Actions = styled(Grid)({
  display: 'flex',
  gap: '1rem',
  flexDirection: 'row',
});
