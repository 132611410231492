import { Grid, ButtonBase } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Header = styled(Grid)({
  width: '100%',
  background: 'white',
  padding: '0 16px',
  borderBottom: `1px solid ${theme.colors.border}`,
});

export const IframeContent = styled(Grid)({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',

  [`& iframe`]: {
    width: '100%',
    height: '100%',
    border: 0,
  },
});

export const Content = styled(Grid)({});

export const Button = styled(ButtonBase)<{ isOpen: boolean }>`
  display: flex;
  text-transform: uppercase;
  color: ${theme.colors.textSecondary};
  margin-bottom: ${({ isOpen }) => (isOpen ? 0 : 1)}rem;
`;
