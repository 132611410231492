import React from 'react';
import { useGetIdentity, usePermissions, useTranslate } from 'react-admin';
import { items } from 'src/components/MenuItem/items';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import {
  Card,
  CardText,
  CardsContainer,
  Container,
  Description,
  Header,
  Title,
} from './styles';

export const Home = () => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const userName = identity?.fullName;
  const hello = userName
    ? `${t('resources.home.hello')}, ${userName}!`
    : `${t('resources.home.hello')}!`;

  const cardItems = items(permissions || []);

  return (
    <Container>
      <Header $isMobile={isMobile}>
        <Title $color="quaternary">{hello}</Title>
        <Title $color="text">{t('resources.home.welcome')}</Title>
        <Description>
          {t('resources.home.description.part1')}
          <br />
          {t('resources.home.description.part2')}
          <br />
          {t('resources.home.description.part3')}
        </Description>
      </Header>

      <CardsContainer $isMobile={isMobile}>
        {cardItems.map((item) => {
          if (item.hasPermission && !item.hideCard) {
            return (
              <Card to={item.path} key={item.name}>
                {item.cardIcon}
                <CardText>{t(`resources.${item.name}.name`)}</CardText>
              </Card>
            );
          }
        })}
      </CardsContainer>
    </Container>
  );
};
