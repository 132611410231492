import { Menu, MenuOpen } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { useTranslate } from 'ra-core';
import * as React from 'react';

export type CustomSidebarToggleButtonProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const CustomSidebarToggleButton = ({
  open,
  setOpen,
}: CustomSidebarToggleButtonProps) => {
  const translate = useTranslate();

  return (
    <Tooltip
      title={translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
        _: 'Open/Close menu',
      })}
      enterDelay={500}
    >
      <IconButton color="inherit" onClick={() => setOpen(!open)}>
        {open ? <MenuOpen /> : <Menu />}
      </IconButton>
    </Tooltip>
  );
};
