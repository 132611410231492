import { SeeAsClientDTO } from '@sinagro/shared';

export type ChoicesProps = {
  id: string;
  name: string;
  document: string;
  crmId?: string;
  hasRegisteredUser: boolean;
};

export const formatChoices = (choices: SeeAsClientDTO[]) => {
  const formattedChoices: ChoicesProps[] = choices.map((item) => {
    return {
      id: item.id,
      name: `${item.name} - ${item.document}`,
      document: item.document,
      crmId: item.crmId,
      hasRegisteredUser: item.hasRegisteredUser,
    };
  });

  return formattedChoices;
};

export const isAValidFilter = (
  newFilter: string,
  filter: string,
  choices: ChoicesProps[]
) => {
  if (filter.length > 1 && newFilter === '') {
    return false;
  }
  const hasFilterEqualFormattedChoice = choices.filter(
    (item) => item.name === newFilter
  );
  return !hasFilterEqualFormattedChoice.length;
};
