import { Delete } from '@mui/icons-material';
import { Permissions } from '@sinagro/shared';
import React, { ReactNode, useState } from 'react';
import {
  Confirm,
  useDelete,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { canAccessManyPermissions } from 'src/utils/permission';

import { Button } from './styles';

type DeleteButtonWithConfirmationProps = {
  buttonLabel: string;
  modal: {
    title: string;
    content: ReactNode;
    buttonConfirmLabel: string;
    buttonCancelLabel: string;
  };
};

export const DeleteButtonWithConfirmation = ({
  buttonLabel,
  modal,
}: DeleteButtonWithConfirmationProps) => {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const record = useRecordContext();

  const canAccessDeleteOption = canAccessManyPermissions(
    [Permissions.CreditPackage_Delete, Permissions.CreditPackage_Full],
    permissions || []
  );

  const [remove, { isLoading }] = useDelete(
    'credit-document-package',
    {
      id: record && record.id,
    },
    {
      onSuccess() {
        notify('Deletado com sucesso', { type: 'success' });
        redirect('/credit-document-package');
      },
      onError() {
        notify('Ocorreu um erro ao tentar deletar', { type: 'error' });
      },
    }
  );

  const handleConfirm = () => {
    remove();
    setOpen(false);
  };

  return (
    <>
      {canAccessDeleteOption && (
        <Button
          size="small"
          variant="contained"
          onClick={handleClick}
          startIcon={<Delete />}
          isMobile={isMobile}
        >
          {t(buttonLabel)}
        </Button>
      )}
      <Confirm
        confirm={modal.buttonConfirmLabel}
        confirmColor="warning"
        cancel={modal.buttonCancelLabel}
        isOpen={open}
        loading={isLoading}
        title={modal.title}
        content={modal.content}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
