import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)<{ isMobile: boolean }>`
  p {
    color: ${({ isMobile }) =>
      isMobile ? theme.colors.primary : theme.colors.text};
    font-weight: ${({ isMobile }) =>
      isMobile ? theme.fontWeight.semibold : theme.fontWeight.regular};
    font-size: ${theme.fontSize.big}px;
    margin-top: 0;
  }
`;
