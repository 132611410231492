import { defaultTheme } from '@sinagro/frontend-shared/themes';
import React from 'react';
import { TextInput, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Modal } from 'src/components/Modal';

import {
  CancelButton,
  Content,
  Fotter,
  ModalText,
  ReproveButton,
} from './styles';

export type RejectionReasonProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onConfirm: (rejectionReason: string) => void;
};

export const RejectionReasonModal = ({
  isModalOpen,
  onClose,
  onConfirm,
}: RejectionReasonProps) => {
  const { getValues, watch } = useFormContext();
  const t = useTranslate();
  const rejectionReasonLength = watch('rejectionReason');
  const handleConfirm = () => {
    const rejectionReason = getValues('rejectionReason');
    onConfirm(rejectionReason);
  };

  return (
    <Modal
      onClose={onClose}
      open={isModalOpen}
      title={t('resources.credit-document-package.edit.modal.title')}
      content={
        <Content>
          <ModalText>
            {t('resources.credit-document-package.edit.modal.contentFirstLine')}
          </ModalText>
          <ModalText>
            {t(
              'resources.credit-document-package.edit.modal.contentSecondLine'
            )}
          </ModalText>
          <TextInput
            source="rejectionReason"
            label={false}
            multiline
            fullWidth
            variant="outlined"
          />
          <Fotter>
            <CancelButton
              label="resources.credit-document-package.edit.modal.cancelButtonLabel"
              onClick={onClose}
            />
            <ReproveButton
              sx={{
                '&:disabled': {
                  backgroundColor: defaultTheme.colors.dangerDisabled,
                  color: defaultTheme.colors.white,
                },
                '&:hover': {
                  backgroundColor: defaultTheme.colors.dangerOnHover,
                  color: defaultTheme.colors.white,
                },
              }}
              disabled={!rejectionReasonLength?.length}
              variant="contained"
              label="resources.credit-document-package.edit.modal.confirButtonLabel"
              onClick={handleConfirm}
            />
          </Fotter>
        </Content>
      }
    />
  );
};
