import { Permissions } from '@sinagro/shared';
import React from 'react';
import { Edit, Form, usePermissions, useTranslate } from 'react-admin';
import ResourceContainer from 'src/components/ResourceContainer';
import { canAccess } from 'src/utils/permission';

import { EditContainer } from './editContainer';
import { Container, DeleteButton } from './styles';
export const UserEdit = () => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const canAccessDeleteOption = canAccess(
    Permissions.User_Write_Delete,
    permissions || []
  );

  return (
    <ResourceContainer title={t('resources.user.edit.title')}>
      <Edit mutationMode="pessimistic">
        <Container setMinHeight={canAccessDeleteOption}>
          <Form>
            <EditContainer />
          </Form>
          {canAccessDeleteOption && (
            <div>
              <DeleteButton
                size="small"
                variant="contained"
                label="resources.user.delete"
                confirmTitle="resources.user.delete"
              />
            </div>
          )}
        </Container>
      </Edit>
    </ResourceContainer>
  );
};
