/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { DateInput, SelectInput, TextInput } from 'react-admin';

import { getDivisionChoices } from './utils';

export const CreditPackageListFilters = () => [
  <TextInput
    key="clientName"
    source="clientName"
    label="resources.credit-document-package.list.filters.clientName"
    variant="outlined"
  />,
  <TextInput
    key="clientId"
    source="clientId"
    label="resources.credit-document-package.list.filters.clientId"
    variant="outlined"
  />,
  <TextInput
    source="salesTeamName"
    key="salesTeamName"
    label="resources.credit-document-package.list.filters.salesTeam"
    variant="outlined"
  />,
  <DateInput
    key="dueDate"
    source="dueDate(min)"
    label="resources.credit-document-package.list.filters.dueDateMin"
    variant="outlined"
    placeholder="dd/mm/aaaa"
  />,
  <DateInput
    key="dueDate"
    source="dueDate(max)"
    label="resources.credit-document-package.list.filters.dueDateMax"
    variant="outlined"
    placeholder="dd/mm/aaaa"
  />,
  <SelectInput
    key="division"
    source="division"
    variant="outlined"
    label="resources.credit-document-package.list.filters.division"
    choices={getDivisionChoices()}
  />,
];
