import AccountCircle from '@mui/icons-material/AccountCircle';
import { Grid, Typography } from '@mui/material';
import { AppBar as MuiAppBar } from '@mui/material';
import { styled as styles } from '@mui/material/styles';
import { styled, css } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { Layout as LayoutAdmin, Link } from 'react-admin';

export const Layout = styled(LayoutAdmin)({
  [`& .RaLayout-content`]: {
    padding: 0,
  },
});

export const ContainerAppBar = styled(Grid)`
  display: flex;
  padding: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  justify-content: space-between;
  align-items: center;
  background: ${theme.colors.white};
  color: ${theme.colors.text};
  border-bottom: 1px solid ${theme.colors.border};
`;

export const StyledAppBar = styles(MuiAppBar, {
  name: 'RaAppBar',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .RaAppBar-toolbar`]: {
    padding: `0 ${theme.spacing(1)}`,
    [theme.breakpoints.down('md')]: {
      minHeight: theme.spacing(6),
    },
  },
  [`& .RaAppBar-menuButton}`]: {
    marginRight: '0.2em',
  },
}));

export const ContainerMenu = styled(Grid)({
  paddingTop: 16,
  width: '100%',
  height: '100%',
});

export const DefaultAvatar = styled(AccountCircle)({
  width: 36,
  height: 36,
  color: theme.colors.textDisabled,
});

export const SidebarHeaderContainer = styled(Grid)<{ $isOpen: boolean }>`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;

  img {
    width: 140px;
  }

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      margin-top: 1.5rem;
      flex-direction: column-reverse;
      align-items: center;
      padding-left: 0.5rem;
      gap: 0.5rem;

      img {
        width: 24px;
      }
    `}
`;

export const LogoContainer = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`;

export const LogoText = styled(Typography)`
  font-size: ${theme.fontSize.xl}px;
  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeight.bold};
  margin-left: 1rem;
`;

export const UserInfoMenu = styled(Grid)`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const UserInfo = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UserName = styled(Typography)`
  font-size: 12px !important;
  font-weight: bold !important;
`;
export const UserRole = styled(Typography)`
  font-size: 10px !important;
  font-weight: bold !important;
  margin: 0 !important;
  color: ${theme.colors.textSecondary} !important;
`;
