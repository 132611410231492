/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CreditPackageClient,
  CreditPackageDocument,
  CreditPackageStatusEnum,
} from '@sinagro/shared';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { v4 } from 'uuid';

export const choicesStatus = () => {
  const allCreditPackageStatus = Object.values(CreditPackageStatusEnum).map(
    (type) => ({
      id: type,
      name: `resources.credit-document-package.edit.status.selectOptions.${type}`,
      disabled: type === CreditPackageStatusEnum.Pending,
    })
  );

  return allCreditPackageStatus;
};

export type FileWithRawFile = {
  rawFile: Blob;
};

export const convertFileToBase64 = (file: FileWithRawFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

export const getDateFormattedAsYearMonthDay = (date: Date) => {
  const NUMBER_TO_CORRECT_VALUE_OF_GET_MONTH = 1; //Since it's a zero-based value where zero indicates the first moth
  const day = date.getDate();
  const month = date.getMonth() + NUMBER_TO_CORRECT_VALUE_OF_GET_MONTH;
  const monthwithTwoDigits = month < 10 ? `0${month}` : month;
  const year = date.getFullYear();
  return `${year}-${monthwithTwoDigits}-${day}`;
};

export const getStorageSignedWriteUrl = async ({
  documentPackageId,
  documentFileId,
  type,
}: {
  documentPackageId: string;
  documentFileId: string;
  type: string;
}) => {
  const storageSignedWriteUrlResponse = await customHttpClient(
    `${apiUrl}/backoffice/credit-document-package/${documentPackageId}/credit-document/${documentFileId}/write`,
    { method: 'post', body: JSON.stringify({ fileType: type }) }
  );
  return JSON.parse(storageSignedWriteUrlResponse.body) as {
    key: string;
    writeUrl: string;
    bucketName: string;
  };
};

export const updateFileToStorage = async ({
  writeUrl,
  name,
  type,
  fileToUpload,
}: {
  writeUrl: string;
  name: string;
  type: string;
  fileToUpload: any;
}) => {
  await customHttpClient(writeUrl, {
    method: 'put',
    headers: {
      'Content-type': `${type}`,
      'Content-Disposition': `inline; filename="${name}"`,
    },
    body: fileToUpload,
  });
};
type FileBlob = {
  id?: string;
  src: string;
  title: string;
  rawFile: Blob;
};
export type DocumentValueType = {
  id?: string;
  description: string;
  file?: FileBlob | CreditPackageDocument['file'];
};

export type HandleUploadFileParams = {
  documents: DocumentValueType[];
  documentPackageId: string;
};

export type CreditDocument = Omit<CreditPackageDocument, 'status'>;

export const getRelatedClientFromClientById = (
  parentClient: CreditPackageClient,
  clientId?: string
) => {
  if (!clientId) undefined;
  const clientsRelatedsPlusParent = [
    { id: parentClient.id, name: parentClient.name },
    ...parentClient.children,
  ];
  return clientsRelatedsPlusParent.find((client) => client.id === clientId);
};

export const handleUploadFile = async ({
  documents,
  documentPackageId,
}: HandleUploadFileParams): Promise<CreditDocument[]> => {
  const processedDocuments: CreditDocument[] = await Promise.all(
    documents.map(async (document) => {
      const documentFileId = v4();
      if (!(document.file as FileBlob)?.rawFile) {
        if (
          !document.file ||
          !(document.file as CreditPackageDocument['file'])?.url
        )
          return {
            ...document,
            id: document.id || documentFileId,
            file: undefined,
          };

        const fileAsCreditPackageDocumentFile =
          document.file as CreditPackageDocument['file'];
        return {
          ...document,
          id: document.id || documentFileId,
          file: fileAsCreditPackageDocumentFile,
        };
      }

      const file = document.file as FileBlob;

      const { key, writeUrl, bucketName } = await getStorageSignedWriteUrl({
        documentFileId,
        documentPackageId,
        type: file.rawFile.type,
      });

      await updateFileToStorage({
        fileToUpload: file.rawFile,
        name: file.title,
        writeUrl,
        type: file.rawFile.type,
      });

      const storageSignedReadUrlResponse = await customHttpClient(
        `${apiUrl}/backoffice/credit-document-package/${documentPackageId}/credit-document/${documentFileId}/read`
      );

      const storageSignedReadUrl = JSON.parse(
        storageSignedReadUrlResponse.body
      ).readUrl;

      return {
        ...document,
        id: document.id || documentFileId,
        file: {
          url: storageSignedReadUrl,
          key,
          bucketName: bucketName,
          filename: (document.file as FileBlob).title,
        },
      };
    })
  );

  return processedDocuments;
};

export const patchDocumentFile = async (
  documentId: string,
  documentPackageId: string,
  documentFile: File | null
) => {
  if (!documentFile) {
    return customHttpClient(
      `${apiUrl}/backoffice/credit-document/${documentId}/file`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          file: null,
        }),
      }
    );
  }

  if (documentFile.type === 'change') return;

  const { key, writeUrl, bucketName } = await getStorageSignedWriteUrl({
    documentFileId: documentId,
    documentPackageId,
    type: documentFile.type,
  });

  await updateFileToStorage({
    fileToUpload: documentFile,
    name: documentFile.name,
    writeUrl,
    type: documentFile.type,
  });

  const storageSignedReadUrlResponse = await customHttpClient(
    `${apiUrl}/backoffice/credit-document-package/${documentPackageId}/credit-document/${documentId}/read`
  );

  const storageSignedReadUrl = JSON.parse(
    storageSignedReadUrlResponse.body
  ).readUrl;

  return customHttpClient(
    `${apiUrl}/backoffice/credit-document/${documentId}/file`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        file: {
          url: storageSignedReadUrl,
          bucketName,
          key,
          filename: documentFile.name,
        },
      }),
    }
  );
};
