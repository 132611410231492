import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { Link as LinkAdmin } from 'react-admin';

export const Container = styled(Grid)({
  display: 'flex',
  padding: 24,
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Image = styled('img')({
  width: '100%',
  maxWidth: 400,
  marginBottom: 32,
});

export const Link = styled(LinkAdmin)({
  padding: 16,
  paddingInline: 24,
  background: theme.colors.primary,
  borderRadius: 8,
  color: theme.colors.white,
  textTransform: 'uppercase',
  fontWeight: theme.fontWeight.semibold,
});

export const Title = styled(Typography)({
  color: theme.colors.primary,
  fontSize: theme.fontSize.big,
  fontWeight: theme.fontWeight.semibold,
  textAlign: 'center',
});

export const Text = styled(Typography)({
  color: theme.colors.textSecondary,
  fontSize: theme.fontSize.lg,
  marginBottom: 32,
  textAlign: 'center',
});
