import { ChevronLeft } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import {
  IDigitalConsultantRelatedClient,
  IRelatedSalesTeam,
  IRole,
  IUser,
} from '@sinagro/shared';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  FormDataConsumer,
  RadioButtonGroupInput,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { apiUrl } from 'src/components/AppDataProvider';
import BackButton from 'src/components/BackButton';
import customHttpClient from 'src/components/CustomHttpClient';
import Subtitle from 'src/components/Subtitle';
import { identifyError } from 'src/utils/error';

import CreditAnalyst from './creditAnalyst';
import DigitalConsultant from './digitalConsultant';
import { Header, Options } from './styles';

type OptionsProps = {
  id: string;
  name: string;
  isDigitalConsultant: boolean;
  isCreditAnalyst: boolean;
};

export const EditContainer = () => {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { setValue } = useFormContext();
  const [isLoading, setIsLoading] = useState(true);
  const [permissionLevelChoices, setPermissionLevelChoices] = useState<
    OptionsProps[]
  >([]);
  const [selectedPermission, setSelectedPermission] = useState('');

  const isDigitalConsultant =
    permissionLevelChoices.filter(
      (item) => item.id === selectedPermission && item.isDigitalConsultant
    ).length > 0;
  const isCreditAnalyst =
    permissionLevelChoices.filter(
      (item) => item.id === selectedPermission && item.isCreditAnalyst
    ).length > 0;

  const handleCancel = () => {
    redirect('/user');
  };

  const fetchRoles = useCallback(async () => {
    setIsLoading(true);
    return customHttpClient(`${apiUrl}/backoffice/role`, {
      method: 'get',
    })
      .then((res) => {
        const data = res.json.map((item: IRole) => {
          return {
            id: item.id,
            name: item.name,
            isDigitalConsultant: item.isDigitalConsultant,
            isCreditAnalyst: item.isCreditAnalyst,
          };
        });
        setPermissionLevelChoices(data);
      })
      .catch((error) => {
        notify(identifyError(error), { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [notify]);

  const updateDigitalRelatedClients = (
    relatedClientsList: IDigitalConsultantRelatedClient[]
  ) => {
    const sanitazedRelatedClients = relatedClientsList.map(
      ({ id, name, document, crmId }) => {
        return {
          id,
          name,
          document,
          crmId,
        };
      }
    );
    setValue('digitalConsultantRelatedClients', sanitazedRelatedClients);
  };

  const updateRelatedSalesTeam = (
    relatedSalesTeamList: IRelatedSalesTeam[]
  ) => {
    const sanitazedRelatedSalesTeams = relatedSalesTeamList.map(
      ({ id, name }) => ({ id, name })
    );
    setValue('relatedSalesTeam', sanitazedRelatedSalesTeams);
  };

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return (
    <>
      <Header>
        <BackButton redirectTo="list" resource="user" />
        <Subtitle text={t('resources.user.edit.subtitle')} />
      </Header>
      <TextInput
        type="text"
        source="name"
        label="resources.user.edit.name"
        variant="outlined"
        required
        fullWidth
        style={{ margin: 0 }}
      />
      <TextInput
        type="email"
        source="email"
        label="resources.user.edit.email"
        variant="outlined"
        required
        fullWidth
        disabled
        style={{ margin: 0 }}
      />
      {isLoading ? (
        <CircularProgress size={20} style={{ marginBottom: 16 }} />
      ) : (
        <FormDataConsumer>
          {({ formData }) => {
            setSelectedPermission(formData.rbacRoleId);
            return (
              <>
                <RadioButtonGroupInput
                  source="rbacRoleId"
                  label="resources.user.edit.permissionLevel"
                  choices={permissionLevelChoices}
                  validate={required()}
                />
                {isDigitalConsultant && (
                  <DigitalConsultant
                    user={formData as IUser}
                    updateRelatedClients={updateDigitalRelatedClients}
                  />
                )}
                {isCreditAnalyst && (
                  <CreditAnalyst
                    user={formData as IUser}
                    updateRelatedSalesTeam={updateRelatedSalesTeam}
                  />
                )}
              </>
            );
          }}
        </FormDataConsumer>
      )}

      <Options>
        <Button
          size="small"
          variant="outlined"
          startIcon={<ChevronLeft />}
          onClick={handleCancel}
        >
          {t('resources.user.edit.cancel')}
        </Button>
        <Button
          size="small"
          type="submit"
          variant="contained"
          disabled={isLoading}
          style={{
            background: theme.colors.backoffice.blue,
            color: theme.colors.white,
          }}
        >
          {t('resources.user.edit.save')}
        </Button>
      </Options>
    </>
  );
};
