import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { Button } from 'react-admin';

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ReproveButton = styled(Button)`
  background-color: ${theme.colors.danger};
  color: ${theme.colors.white};
  font-size: ${theme.fontSize.md}px;
`;

export const Fotter = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)`
  background-color: ${theme.colors.white};
  color: ${theme.colors.primary};
  background: transparent;
  border: 1px solid ${theme.colors.primary};
  font-size: ${theme.fontSize.md}px;
`;

export const ModalText = styled(Typography)`
  color: ${theme.colors.secondary};
`;
