import { minLength, regex } from 'react-admin';
import {
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneSymbol,
  atLeastOneUppercase,
  notAcceptSpaces,
} from 'src/utils/regexes';

export const passwordValidations = [
  minLength(8),
  regex(
    atLeastOneLowercase,
    'passwordRecovery.validations.atLeastOneLowercase'
  ),
  regex(
    atLeastOneUppercase,
    'passwordRecovery.validations.atLeastOneUppercase'
  ),
  regex(atLeastOneNumber, 'passwordRecovery.validations.atLeastOneNumber'),
  regex(atLeastOneSymbol, 'passwordRecovery.validations.atLeastOneSymbol'),
  regex(notAcceptSpaces, 'passwordRecovery.validations.notAcceptSpaces'),
];
