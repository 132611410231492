import { CreditPackage } from '@sinagro/shared';
import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  TextField,
} from 'react-admin';
import CreditDocumentGroupedDivisionChips from 'src/components/CreditDocumentDivisionChips';
import CreditDocumentStatusChip from 'src/components/CreditDocumentStatusChip';
export const ListCreditDocumentPackages = () => {
  return (
    <Datagrid size="medium" hover={false} bulkActionButtons={false}>
      <TextField
        source="client.name"
        label="resources.credit-document-package.list.header.client.name"
        sortable={false}
      />
      <TextField
        source="client.id"
        label="resources.credit-document-package.list.header.client.id"
        sortable={false}
      />
      <FunctionField
        source="division"
        label="resources.credit-document-package.list.header.division"
        sortable={false}
        render={(record: CreditPackage | undefined) =>
          record?.division && record?.division?.length > 0 ? (
            <CreditDocumentGroupedDivisionChips divisions={record.division} />
          ) : (
            '-'
          )
        }
      />
      <TextField
        source="lastRelatedQuote.salesGroupDescription"
        label="resources.credit-document-package.list.header.salesTeam"
        sortable={false}
      />
      <DateField
        source="requestedAt"
        label="resources.credit-document-package.list.header.requestedAt"
        sortable={false}
        options={{ timeZone: 'UTC' }}
      />
      <DateField
        source="dueDate"
        label="resources.credit-document-package.list.header.dueDate"
        sortable={false}
        options={{ timeZone: 'UTC' }}
      />
      <FunctionField
        source="internalStatus"
        sortBy="priority"
        sortable={false}
        label="resources.credit-document-package.list.header.status"
        render={(record: CreditPackage | undefined) =>
          record?.internalStatus ? (
            <CreditDocumentStatusChip type={record.internalStatus} />
          ) : (
            '-'
          )
        }
      />
      <EditButton
        label="resources.credit-document-package.list.header.details"
        startIcon={false}
        sx={() => ({
          backgroundColor: '#1976d2',
          color: 'white',
          paddingX: 1,
          paddingY: 1,
          ':hover': {
            backgroundColor: '#1976d2',
          },
        })}
      />
    </Datagrid>
  );
};
