/* eslint-disable @typescript-eslint/no-explicit-any */
import { SSOEventTypeEnum } from '@sinagro/frontend-shared/src/types';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { auth } from 'src/config/firebase';

import {
  PORTAL_URL,
  sendAuthenticatedMessage,
  sendCheckAuthMessage,
  sendNotAuthenticatedMessage,
} from './utils';

window.addEventListener(
  'message',
  async (event: any) => {
    await new Promise((res) => setTimeout(res, 2000));

    const eventOrigin = event.origin;
    const isAnAllowedOrigin = PORTAL_URL === eventOrigin;

    if (event.data.type !== SSOEventTypeEnum.CheckAuth || !isAnAllowedOrigin)
      return;
    sendCheckAuthMessage(event, eventOrigin);

    const existsLoggedUser = auth.currentUser;

    if (!existsLoggedUser) {
      return sendNotAuthenticatedMessage(event, eventOrigin);
    }

    await customHttpClient(`${apiUrl}/user/sso/login`, {
      method: 'post',
    })
      .then((res) => {
        const token = JSON.parse(res.body).token;
        if (!token) {
          return sendNotAuthenticatedMessage(event, eventOrigin);
        }
        return sendAuthenticatedMessage(event, eventOrigin, token);
      })
      .catch((_) => {
        return sendNotAuthenticatedMessage(event, eventOrigin);
      });
  },
  false
);

export const sso = '';
