import { Grid, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)<{ $isMobile: boolean }>((props) => ({
  background: theme.colors.white,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: props.$isMobile ? '80%' : 500,
  borderRadius: 8,
  padding: props.$isMobile ? 16 : 24,
}));

export const Header = styled(Grid)<{ $isMobile: boolean }>((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: props.$isMobile ? 16 : 24,
}));

export const Title = styled(Typography)({
  color: theme.colors.text,
  fontWeight: 500,
  fontSize: theme.fontSize.xxl,
});

export const CloseButton = styled(IconButton)({
  ['svg']: {
    fontSize: theme.fontSize.xxl,
  },
});
