import { WarningAmberOutlined } from '@mui/icons-material';
import { Grid, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)`
  margin-bottom: 2rem;
  border-bottom: 1px solid ${theme.colors.primary};
`;

export const RequestContainer = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1rem;
`;

export const RequestButton = styled(Button)`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  width: 'auto';
  height: 36px;
  margin-top: 8px;
`;

export const CancelButton = styled(Button)`
  color: ${theme.colors.primary};
  width: 'auto';
  height: 36px;
  margin-top: 8px;
`;

export const RequestsHistory = styled(Typography)`
  color: ${theme.colors.textSecondary};
  font-size: ${theme.fontSize.md}px;
`;

export const ModalContent = styled(Grid)``;

export const AlertContainer = styled(Grid)`
  background-color: ${theme.colors.backoffice.lightYellow};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
`;

export const AlertText = styled(Typography)`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSize.lg}px;
`;

export const AlertIcon = styled(WarningAmberOutlined)`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSize.xxl}px;
`;

export const ModalText = styled(Typography)`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSize.md}px;
  margin-top: 0.5rem;
`;

export const ModalFooter = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export const RequiredField = styled(Typography)`
  color: ${theme.colors.danger};
  font-size: ${theme.fontSize.md}px;
  margin-bottom: 1rem;
  margin-top: -1rem;
`;
