import { useMediaQuery, Theme, useScrollTrigger } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import * as React from 'react';
import { useSidebarState } from 'react-admin';
import { Sidebar as SidebarAdmin } from 'react-admin';
import { isSeeAsRoute } from 'src/utils/route';

import { SeeAsClient } from '../SeeAsClient';
import { Faq } from './Faq';
import SidebarHeader from './SidebarHeader';

export interface SidebarProps {
  children: React.ReactElement;
  closedSize?: number;
  size?: number;
}

const CustomSidebar = (props: SidebarProps) => {
  const { children, ...rest } = props;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const [open, setOpen] = useSidebarState();
  const trigger = useScrollTrigger();

  const toggleSidebar = () => setOpen(!open);
  const isSeeAs = isSeeAsRoute();

  return isXSmall ? (
    <Sidebar
      variant="temporary"
      open={open}
      onClose={toggleSidebar}
      classes={SidebarClasses}
      {...rest}
    >
      <div
        className={SidebarClasses.fixed}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <>
            <SidebarHeader />
            {children}
          </>
        </div>
        <div style={{ fontSize: '14px', alignSelf: 'center' }}>
          <Faq open={open} />
        </div>
      </div>
    </Sidebar>
  ) : (
    <Sidebar
      variant="permanent"
      open={open}
      onClose={toggleSidebar}
      classes={SidebarClasses}
      className={trigger ? SidebarClasses.appBarCollapsed : ''}
      {...rest}
    >
      <div
        className={SidebarClasses.fixed}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <>
            <SidebarHeader />
            {children}
            {isSeeAs && open && <SeeAsClient />}
          </>
        </div>
        <Faq open={open} />
      </div>
    </Sidebar>
  );
};

export default CustomSidebar;

const PREFIX = 'RaSidebar';

export const SidebarClasses = {
  docked: `${PREFIX}-docked`,
  paper: `${PREFIX}-paper`,
  paperAnchorLeft: `${PREFIX}-paperAnchorLeft`,
  paperAnchorRight: `${PREFIX}-paperAnchorRight`,
  paperAnchorTop: `${PREFIX}-paperAnchorTop`,
  paperAnchorBottom: `${PREFIX}-paperAnchorBottom`,
  paperAnchorDockedLeft: `${PREFIX}-paperAnchorDockedLeft`,
  paperAnchorDockedTop: `${PREFIX}-paperAnchorDockedTop`,
  paperAnchorDockedRight: `${PREFIX}-paperAnchorDockedRight`,
  paperAnchorDockedBottom: `${PREFIX}-paperAnchorDockedBottom`,
  modal: `${PREFIX}-modal`,
  fixed: `${PREFIX}-fixed`,
  appBarCollapsed: `${PREFIX}-appBarCollapsed`,
};

export const Sidebar = styled(SidebarAdmin)({
  background: theme.colors.white,
  borderRight: `1px solid ${theme.colors.border}`,
});

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 55;
