/* eslint-disable @typescript-eslint/no-explicit-any */
import polyglotI18nProvider from 'ra-i18n-polyglot';
import ptBrMessages from 'ra-language-pt-br';

const translate = {
  ...ptBrMessages,
  ra: {
    ...ptBrMessages.ra,
    action: {
      ...ptBrMessages.ra.action,
      remove_all_filters: 'Remover todos os filtros',
      item_removal_confirmation: {
        title: 'Remover item',
        content: 'Tem certeza que deseja remover o item?',
      },
    },
  },
  resources: {
    sidebar: {
      support: 'Ver Dúvidas Frequentes do Portal',
    },
    home: {
      title: 'Início',
      name: 'Início',
      hello: 'Olá',
      welcome: 'Bem-vindo ao Backoffice do Portal do Cliente.',
      description: {
        part1:
          'Este é o ambiente para colaboradores Sinova conferirem informações relacionadas ao Portal do Cliente.',
        part2:
          'É possível acessar o Portal como um cliente e visualizar como cada um vê seus dados no Portal.',
        part3:
          'Consultores, gerentes de loja e equipe de crédito poderão enviar e conferir os documentos para análise de crédito.',
      },
    },
    user: {
      title: 'Usuários do backoffice',
      name: 'Usuários do backoffice',
      list: {
        filters: {
          name: 'Nome do usuário',
          email: 'E-mail',
          rbacRoleId: 'Nível de permissão',
        },
      },
      fields: {
        name: 'Nome do usuário',
        email: 'E-mail Sinova',
        phoneNumber: 'Telefone do usuário',
        rbacRole: {
          name: 'Nível de permissão',
        },
        backofficeInvite: {
          status: 'Status do convite',
        },
      },
      createUser: 'Novo usuário',
      create: {
        title: 'Novo usuário',
        subtitle: 'Convidar novo usuário',
        name: 'Nome do usuário',
        email: 'E-mail do usuário',
        permissionLevel: 'Nível de permissão',
        cancel: 'Cancelar',
        sendInvite: 'Enviar convite',
      },
      edit: {
        title: 'Editar usuário',
        subtitle: 'Cadastro do usuário',
        name: 'Nome do usuário',
        email: 'E-mail do usuário',
        permissionLevel: 'Nível de permissão',
        cancel: 'Cancelar',
        save: 'Salvar',
        digitalConsultant: {
          instructions:
            'Selecione quais clientes da Sinova o consultor Digital poderá visualizar',
          addClient: 'Adicionar cliente',
          document: 'CPF/CNPJ',
          crmId: 'ID CRM',
          clientAlreadyRelated: 'Cliente já relacionado',
        },
        creditAnalyst: {
          search: 'Busque por nome da loja / equipe de venda',
          instructions:
            'Selecione quais lojas da Sinova o usuário de crédito é responsável.',
          info: 'Só serão mostradas para este usuário as listas de documentos de clientes das lojas selecionadas abaixo.',
          addSalesTeam: 'Adicionar loja',
          name: 'Nome da loja',
          salesTeamAlreadyRelated: 'Loja já relacionada',
        },
      },
      delete: 'Excluir usuário',
    },
    seeAs: {
      title: 'Visualize o Portal como um cliente específico',
      name: 'Acessar como cliente',
      instruction:
        'Busque um nome ou CPF/ CNPJ para ver os dados no Portal do Cliente',
      registered: 'Cadastrado no Portal',
      noRegistered: 'Não Cadastrado no Portal',
      openPortal: 'Abrir o Portal',
      search: 'Busca',
      searchInstruction: 'Busque um nome ou CPF/ CNPJ',
      supportForm: 'Suporte tech - Relatar inconsistência no Portal',
      supportFormShort: 'Suporte tech Portal',
      info: {
        title: 'Acessar Portal com um Cliente',
        message:
          'Somente após selecionar um cliente, é possível abrir o Portal em outra aba e visualizar os dados do cliente selecionado.',
        ok: 'Entendi',
      },
      seeMore: 'Ver mais',
      seeLess: 'Ver menos',
    },
    'portal/user': {
      title: 'Usuários do Portal',
      name: 'Usuários do Portal',
      fields: {
        name: 'Nome do usuário',
        email: 'E-mail',
        hasTorqFarmRelated: 'Cadastro TORQ',
        phoneNumber: 'Telefone',
        clientType: 'Tipo de cliente',
        type: 'Tipo de cliente',
        mainClientId: 'ID SAP do cliente superior',
        sapId: 'ID SAP',
        mainCrmClientId: 'ID CRM do cliente superior',
        crmId: 'ID CRM',
        createdAt: 'Data do cadastro',
        document: 'CPF/CNPJ',
        'createdAt(min)': 'Data do cadastro (início)',
        'createdAt(max)': 'Data do cadastro (fim)',
      },
      hasTorqFarmRelated: {
        true: 'Sim',
        false: 'Não',
        yes: 'Usuário cadastrado',
        no: 'Usuário não cadastrado',
      },
      order: 'Ordenar',
    },
    'credit-document-package': {
      title: 'Gestão Documentos de Crédito',
      name: 'Documentos de Crédito',
      edit: {
        mutationSuccess: 'Solicitação Atualizada',
        buttonLabel: 'Salvar',
        title: 'Documentos de Crédito',
        subtitle: 'Editar Documentos de Crédito',
        patchFileSuccess: 'Arquivo atualizado com sucesso',
        patchFileFailed: 'Falha ao tentar atualizar o arquivo',
        selectFileFailed:
          'Não foi possível selecionar o arquivo. O tamanho máximo permitido é de 10 MB e os tipos de arquivo válidos são: JPEG, PNG, PDF, XLSX ou XML',
        status: {
          selectLabel: 'Status',
          selectOptions: {
            approved: 'Validado',
            pending: 'Pendente',
            rejected: 'Reprovado',
            review: 'Em revisão',
          },
          successNotification: 'Status alterado com sucesso',
          errorNotification: 'Erro ao tentar atualizar o status',
        },
        modal: {
          title: 'Deseja reprovar este documento',
          contentFirstLine: 'Essa ação não poderá ser desfeita.',
          contentSecondLine: 'Descreva o motivo da reprovação abaixo:',
          cancelButtonLabel: 'Cancelar',
          confirButtonLabel: 'Reprovar',
        },
      },
      delete: {
        button: {
          label: 'Excluir Solicitação',
        },
        modal: {
          title: 'Deseja excluir esta solicitação de documentos?',
          contentSubtitle: 'Essa ação não poderá ser desfeita',
          contentText:
            'Todos os dados e documentos inseridos serão excluídos do Backoffice e da página de créditos no Portal do Cliente Sinova',
          confirmLabel: 'Excluir',
          cancelLabel: 'Cancelar',
        },
      },
      list: {
        title: 'Documentos de Crédito',
        subtitle: 'Lista de documentos',
        create: 'NOVA DEMANDA DE DOCS',
        filters: {
          clientName: 'Nome do Cliente',
          clientId: 'Id do Cliente',
          salesTeam: 'Equipe de venda',
          dueDateMin: 'Prazo de envio mín.',
          dueDateMax: 'Prazo de envio máx.',
          division: 'Divisão',
        },
        header: {
          client: {
            id: 'ID Superior de crédito',
            name: 'Nome do cliente',
          },
          division: 'Divisão',
          salesTeam: 'Equipe de Vendas',
          harvest: 'Safra',
          cultivation: 'Cultura',
          status: 'Status da Solicitação',
          requestedAt: 'Data de solicitação',
          dueDate: 'Prazo para envio',
          details: 'Detalhes',
          remove: 'Remover',
        },
      },
      create: {
        mutationSuccess: 'Solicitação Criada',
        title: 'Documentos de Crédito',
        subtitle: 'Criar nova demanda de documentos',
        creditLimit: 'Limite de crédito solicitado (R$)',
        winningQuote: 'Total de cotações ganhas (R$)',
        dueDate: 'Data limite de envio de docs',
        responsibleConsultant: 'Consultor de Insumos Responsável',
        responsibleLivestockConsultant: 'Consultor Pecuária Responsável',
        creditAnalyst: 'Analista de Crédito',
        clientId: 'ID SAP do Cliente Superior',
        clientName: 'Nome Completo ou Razão Social',
        clientDocument: 'CPF/CNPJ',
        clientChildren: 'Parceiros',
        partnerId: 'ID SAP do Parceiro',
        partnerName: 'Nome do Parceiro',
        cultivation: 'Cultura',
        harvestReference: 'Safra',
        ownedPlantingArea: 'Área de Plantio Própria (ha)',
        leasedPlantingArea: 'Área de Plantio Arrendada (ha)',
        requestDocuments: 'Solicitar docs',
        selectDocuments: 'Documentos que precisam ser adicionados à proposta:',
        description: 'Documento',
        additionalText: 'Texto complementar',
        file: '',
        download: 'Download',
        uploadedBy: 'Responsável pelo upload',
        uploadedAt: 'Data de upload',
        validUntil: 'Validade',
        fileInstructions: 'Clique para adicionar',
        addDocument: 'Adicionar Documento',
        relatedClient: 'Cliente/ID',
        addClientToDocument: 'Cliente',
        rejectionReason: 'Motivo da reprovação',
      },
      file: {
        removeItem: 'Excluir doc',
        confirmRemoval: {
          title: 'Deseja excluir o arquivo anexado em',
          message:
            'Essa ação não poderá ser desfeita e o arquivo anexado será removido.',
        },
        confirmRemovalItem: {
          title: 'Deseja excluir o documento da solicitação?',
          content:
            'Essa ação não poderá ser desfeita. Todos os dados inseridos nessa linha serão perdidos.',
        },
      },
      downloadAllDocuments: {
        button: 'Baixar todos os documentos',
        success: 'Download realizado com sucesso',
        error: 'Erro ao baixar os documentos',
      },
      creditRequest: {
        value: 'Crédito total solicitado ciclo (R$)',
        request: 'Solicitar',
        cancel: 'Cancelar',
        modal: {
          title: 'Solicitar novo crédito para o ciclo',
          descPart1:
            'Ao solicitar um novo valor total de crédito para este cliente, será verificado se são necessários novos documentos.',
          descPart2:
            'Em seguida, os documentos serão solicitados via Portal do Cliente.',
          warning: 'Esta ação não poderá ser desfeita.',
        },
        history: {
          requested: 'solicitados',
          by: 'Por:',
          requestedDocs: 'Docs solicitados ao cliente.',
        },
        success: 'Documentos atualizados para o valor solicitado.',
        requiredField:
          'É necessário informar o total de crédito a ser solicitado',
      },
    },
  },
  common: {
    backoffice: {
      title: 'Backoffice Portal do Cliente',
      description:
        'Gestão de usuários, configurações e visualização do Portal como Cliente.',
    },
    logo: 'Backoffice Portal',
    noOption: 'Não localizado',
  },
  login: {
    email: 'E-mail',
    password: 'Senha',
    submit: 'login',
    forgotPassword: 'Esqueceu sua senha?',
    text: 'ESPAÇO DO COLABORADOR',
  },
  forgotPassword: {
    byEmail: 'Insira seu e-mail para receber o link de recuperação de senha',
    email: 'E-mail',
    confirm: 'Confirmar',
    backToLogin: 'Retornar ao Login',
    success: 'Recuperação enviada com sucesso',
  },
  passwordRecovery: {
    create: {
      title: 'Criar nova senha',
      newPassword: 'Nova senha',
      confirmNewPassword: 'Confirmar nova senha',
      createPassword: 'Criar nova senha',
    },
    newUser: {
      title:
        'Cadastre uma senha para acessar o Backoffice do Portal do Cliente Sinova',
      newPassword: 'Senha',
      confirmNewPassword: 'Confirme sua senha',
      createPassword: 'Cadastrar senha',
    },
    helper:
      'Digite uma senha de no mínimo 8 dígitos que contenha letra maiúscula, minúscula, número e caracter especial.',
    validations: {
      atLeastOneLowercase: 'Senha deve possuir no mínimo 1 letra minúscula',
      atLeastOneUppercase: 'Senha deve possuir no mínimo 1 letra maiúscula',
      atLeastOneNumber: 'Senha deve possuir no mínimo 1 número',
      atLeastOneSymbol: 'Senha deve possuir no mínimo 1 caracter especial',
      notAcceptSpaces: 'Senha não deve possuir espaços',
      samePassword: 'Senhas devem ser iguais',
    },
    success: 'Senha criada com sucesso',
  },
  enums: {
    backofficeInviteStatusEnum: {
      sent: 'Enviado',
      accepted: 'Aceito',
    },
    creditDocumentStatusEnum: {
      approved: 'Docs completos',
      pending: 'Docs pendentes',
      rejected: 'Docs reprovados',
      review: 'Docs Em revisão',
    },
    userTypeEnum: {
      lead: 'Lead',
      client: 'Cliente',
      guest: 'Convidado',
    },
    creditPackageDivisionEnum: {
      '10': 'Insumo',
      '15': 'Pecuária',
      unknown: 'Não identificada',
    },
  },
};

const messages: Record<string, any> = {
  'pt-br': translate,
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  'pt-br'
);
