import React, { useCallback, useEffect, useState } from 'react';
import { AutocompleteInput, useNotify } from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { identifyError } from 'src/utils/error';

import { formatChoices, isAValidFilter, ChoicesProps } from './utils';

type SalesTeamSearchAutocompleteProps = {
  setSelectedSalesTeam: (salesTeam: ChoicesProps | null) => void;
  label: string;
  source?: string;
};

const SalesTeamSearchAutocomplete = ({
  setSelectedSalesTeam,
  label,
  source = 'search',
}: SalesTeamSearchAutocompleteProps) => {
  const notify = useNotify();
  const [filter, setFilter] = useState('');
  const [choices, setChoices] = useState<ChoicesProps[]>([]);
  const autocompleteChoices = choices;

  const onChangeSalesTeam = (newSalesTeam: string) => {
    if (newSalesTeam === '') {
      setFilter('');
    }
    const salesTeam = choices.filter((item) => item.id === newSalesTeam)[0];
    setSelectedSalesTeam(salesTeam);
  };

  const onChangeFilter = (newFilter: string) => {
    if (isAValidFilter(newFilter, filter, choices)) {
      setFilter(newFilter);
    }
  };

  const fetchSalesTeam = useCallback(async () => {
    return customHttpClient(`${apiUrl}/backoffice/sales-team?name=${filter}`, {
      method: 'get',
    })
      .then((res) => {
        const data = res.json.results;
        const formattedChoices = formatChoices(data);
        setChoices(formattedChoices);
      })
      .catch((error) => {
        notify(identifyError(error), { type: 'error' });
      });
  }, [notify, filter]);

  useEffect(() => {
    fetchSalesTeam();
  }, [fetchSalesTeam]);

  return (
    <AutocompleteInput
      source={source}
      label={label}
      variant="outlined"
      helperText=""
      choices={autocompleteChoices}
      onChange={onChangeSalesTeam}
      setFilter={onChangeFilter}
      style={{ maxWidth: 330, width: '100%' }}
      noOptionsText="common.noOption"
    />
  );
};

export default SalesTeamSearchAutocomplete;
