import React from 'react';

import { Container, Label, Value } from './styles';

type DefaultFieldProps = {
  label: string;
  value?: string;
  style?: React.CSSProperties;
};

const DefaultField = ({ label, value, style }: DefaultFieldProps) => {
  return (
    <Container style={style}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  );
};

export default DefaultField;
