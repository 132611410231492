/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserTypeEnum } from '@sinagro/shared';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import { formatDate } from 'src/utils/format';

export const getUserTypeChoices = () => {
  const types = Object.values(UserTypeEnum).map((type) => ({
    id: type,
    name: `enums.userTypeEnum.${type}`,
  }));

  return types;
};

export const hasTorqFarmRelatedChoices = [
  { id: true, name: 'resources.portal/user.hasTorqFarmRelated.yes' },
  { id: false, name: 'resources.portal/user.hasTorqFarmRelated.no' },
];

interface UserType {
  lead: 'Lead';
  client: 'Cliente';
  guest: 'Convidado';
}
const getClientTypeTranslated = (userType: string) => {
  const userTypesTranslated: UserType = {
    lead: 'Lead',
    client: 'Cliente',
    guest: 'Convidado',
  };
  const itHasType = Object.keys(userTypesTranslated).find(
    (key) => key === userType
  );
  if (itHasType) return userTypesTranslated[userType as keyof UserType];
  return userType;
};

export const exporter = (users: any) => {
  const postsForExport = users.map((u: any) => {
    return {
      Id: u.id,
      Nome: u.name || '-',
      Email: u.email || '-',
      Telefone: u.phoneNumber || '-',
      Documento: u.document || '-',
      'Tipo Doc': u.documentType,
      'Cadastro TORQ': u.hasTorqFarmRelated ? 'Sim' : 'Não',
      'Tipo de cliente': getClientTypeTranslated(u.type),
      'ID SAP do client superior': u.mainClientId || '-',
      'ID CRM do cliente superior': u.mainCrmClientId || '-',
      'Data do Cadastro': u.createdAt,
    };
  });
  jsonExport(
    postsForExport,
    {
      headers: [
        'Id',
        'Nome',
        'Email',
        'Telefone',
        'Tipo Doc',
        'Documento',
        'Cadastro TORQ',
        'Tipo de cliente',
        'ID SAP do client superior',
        'ID CRM do cliente superior',
        'Data do Cadastro',
      ],
    },
    (err: any, csv: any) => {
      const date = formatDate(new Date());
      downloadCSV(csv, `Usuários Backoffice - ${date}`);
    }
  );
};
