import { Download } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { CreditPackageDocument } from '@sinagro/shared';
import React, { useCallback, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import customHttpClient from 'src/components/CustomHttpClient';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { download } from 'src/utils/download';

import { Button } from './styles';

type DownloadAllDocumentsProps = {
  creditPackageId: string;
  documents: CreditPackageDocument[];
};

const DownloadAllDocuments = ({
  creditPackageId,
  documents,
}: DownloadAllDocumentsProps) => {
  const t = useTranslate();
  const notify = useNotify();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const noDocumentsWithUploadedFile =
    documents.filter((doc) => doc.file?.filename).length === 0;
  const disabled = isLoading || noDocumentsWithUploadedFile;

  const downloadAllDocuments = useCallback(async () => {
    setIsLoading(true);
    await customHttpClient(
      `${apiUrl}/credit-package/${creditPackageId}/download-all-documents`,
      {
        method: 'GET',
      }
    )
      .then((res) => {
        const { downloadLink } = JSON.parse(res.body);
        download(downloadLink);
        notify(
          'resources.credit-document-package.downloadAllDocuments.success',
          { type: 'success' }
        );
      })
      .catch(() => {
        notify('resources.credit-document-package.downloadAllDocuments.error', {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  }, [creditPackageId, notify]);

  return (
    <Button
      size="small"
      variant="contained"
      disabled={disabled}
      isMobile={isMobile}
      disable={disabled}
      onClick={downloadAllDocuments}
      startIcon={
        isLoading ? (
          <CircularProgress style={{ color: theme.colors.white }} size={16} />
        ) : (
          <Download />
        )
      }
    >
      {t('resources.credit-document-package.downloadAllDocuments.button')}
    </Button>
  );
};

export default DownloadAllDocuments;
