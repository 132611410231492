export const formatDate = (date: string | Date | undefined) => {
  if (!date) {
    return;
  }
  const formattedDate = new Date(date).toISOString().slice(0, 10);
  return formattedDate;
};

export const formatDatePTBR = (date: string | Date | undefined) => {
  if (!date) {
    return;
  }
  const formattedDate = new Date(date).toLocaleString('pt-BR', {
    timeZone: 'UTC',
    dateStyle: 'short',
  });
  return formattedDate;
};

export const separateIntegerWithDots = (integers: string) => {
  const parts: string[] = [];
  let part = '';
  integers
    .split('')
    .reverse()
    .forEach((integer, index) => {
      part = integer + part;
      if (part.length === 3 || index === integers.length - 1) {
        parts.unshift(part);
        part = '';
      }
    });

  return parts.join('.');
};

export const formatValueToCurrency = (value: string) => {
  if (!value) return '';
  const valueAsString = value.toString();
  const valueWithOnlyNumbers = valueAsString
    .replace(/^0+/, '')
    .replace(/[^0-9]/g, '');
  const decimalNumbers = valueWithOnlyNumbers.slice(
    valueWithOnlyNumbers.length - 2
  );
  const integerNumbers =
    valueWithOnlyNumbers.slice(0, valueWithOnlyNumbers.length - 2) || '0';
  const integerSeparetedWithDots = separateIntegerWithDots(integerNumbers);
  return integerSeparetedWithDots + ',' + decimalNumbers;
};

export const formatCurrencyToValue = (value: string) => {
  if (!value) return 0;
  const separetedValue = value.replace(/[.,]+/g, '').split(',');
  const formattedValue = parseInt(`${separetedValue[0]}${separetedValue[1]}`);
  return formattedValue;
};
