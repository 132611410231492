import React, { useState } from 'react';
import { Form, Link, TextInput, useNotify, useTranslate } from 'react-admin';
import { apiUrl } from 'src/components/AppDataProvider';
import AuthLayout from 'src/components/AuthLayout';
import Button from 'src/components/Button';
import customHttpClient from 'src/components/CustomHttpClient';
import { identifyError } from 'src/utils/error';

import { Container, Content, Footer } from './styles';

type ForgotPasswordFormProps = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const t = useTranslate();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const onSubmit = async ({ email }: ForgotPasswordFormProps) => {
    setLoading(true);
    if (email === '') {
      return;
    }

    await customHttpClient(`${apiUrl}/user/password/recovery?backoffice=true`, {
      method: 'post',
      body: JSON.stringify({
        emailOrPhone: email,
      }),
    })
      .then(() => {
        notify(t('forgotPassword.success'), { type: 'success' });
      })
      .catch((error) => {
        notify(identifyError(error), { type: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthLayout title={t('forgotPassword.byEmail')}>
      <Container>
        <Content>
          <Form onSubmit={(data) => onSubmit(data as ForgotPasswordFormProps)}>
            <TextInput
              type="email"
              source="email"
              label="forgotPassword.email"
              variant="outlined"
              required
              fullWidth
              style={{ margin: 0 }}
            />
            <Button text={t('forgotPassword.confirm')} loading={loading} />
          </Form>
          <Footer>
            <Link to="/login">{t('forgotPassword.backToLogin')}</Link>
          </Footer>
        </Content>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPassword;
