import { CreditPackageClient } from '@sinagro/shared';
import React from 'react';

export type CreditDocumentPackageType = {
  selectedClient: CreditPackageClient | null;
  onChangeClient?: (client: CreditPackageClient | null) => void;
};

export const CreditDocumentPackageContext =
  React.createContext<CreditDocumentPackageType>({
    selectedClient: null,
  });

export const useCreditDocumentPackageContext = (): React.ContextType<
  React.Context<CreditDocumentPackageType>
> => {
  return React.useContext(CreditDocumentPackageContext);
};
