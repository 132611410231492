import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const Container = styled(Grid)<{ isMobile: boolean }>((props) => ({
  marginTop: 32,
  [`& p`]: {
    color: theme.colors.text,
    fontSize: props.isMobile ? theme.fontSize.lg : theme.fontSize.xxl,
    fontWeight: 600,
  },
}));
