import { CreditPackageDivisionEnum } from '@sinagro/shared';
import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import CreditDocumentDivisionChip from './chip';
import { Group } from './styles';

type CreditDocumentGroupedDivisionChipsProps = {
  divisions: CreditPackageDivisionEnum[];
};

const CreditDocumentGroupedDivisionChips = ({
  divisions,
}: CreditDocumentGroupedDivisionChipsProps) => {
  const isMobile = useIsMobile();

  return (
    <Group isMobile={isMobile && divisions.length > 1}>
      {divisions.map((division) => (
        <CreditDocumentDivisionChip type={division} key={division} />
      ))}
    </Group>
  );
};

export default CreditDocumentGroupedDivisionChips;
