import React, { useEffect } from 'react';
import { useAuthState, useLogin, useRedirect, useTranslate } from 'react-admin';
import { ActiveDirectoryLogin } from 'src/components/ActiveDirectory';
import AuthLayout from 'src/components/AuthLayout';

import { Container, Content, Text } from './styles';

const LoginPage: React.FC = () => {
  const auth = useAuthState();
  const redirect = useRedirect();
  const login = useLogin();
  const t = useTranslate();

  useEffect(() => {
    if (auth.authenticated && !auth.isLoading) {
      redirect('/');
    }
  }, [auth, redirect]);

  const onMsLoggedSuccessfull = (idToken: string) => {
    login({ fromActiveDirectory: true, idToken });
  };

  return (
    <AuthLayout title={t('common.backoffice.title')}>
      <Container>
        <Content>
          <Text>{t('login.text')}</Text>
          <ActiveDirectoryLogin onLoggedSuccessfull={onMsLoggedSuccessfull} />
        </Content>
      </Container>
    </AuthLayout>
  );
};

export default LoginPage;
