import { Close } from '@mui/icons-material';
import { Modal as ModalMUI } from '@mui/material';
import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Container, Header, Title, CloseButton } from './styles';

type ModalProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  content: React.ReactElement;
};

export const Modal = ({ open, title, onClose, content }: ModalProps) => {
  const isMobile = useIsMobile();

  return (
    <ModalMUI open={open} onClose={onClose}>
      <Container $isMobile={isMobile}>
        <Header $isMobile={isMobile}>
          <Title>{title}</Title>
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        </Header>
        {content}
      </Container>
    </ModalMUI>
  );
};
